import { AggregatedTracker, AggregatedTrackerNew, Cookie, Purpose } from '@types';
import { getInitiatorVendorIdentifier, getInitiatorVendorLabel, getTrackerAgeInMonthOrDays, getTrackerAgeInMonthsNumeric, getVendorIdentifier, getVendorLabel } from '@utils';
import { computeFunctionalScenario } from './computeFunctionalScenario';

/**
 * Defines the trackers data based on the last report
 *
 * @param {Cookie[]} trackers - The report containing tracker information
 * @param purposes
 * @returns {AggregatedTracker[]} - The list of aggregated trackers
 */
export const defineTrackersTableData = (trackers: any[], purposes: Purpose[] = []): AggregatedTracker[] => {
  return trackers.map(tracker => {
    return {
      id: tracker.id,
      name: tracker.name,
      sld: tracker.sld || null,
      domain: tracker.domain,
      host: tracker.host,
      party: tracker.is_third_party ? '3rd party' : '1st party',
      first_party: !tracker.is_third_party,
      initiator: {
        identifier: getInitiatorVendorIdentifier(tracker),
        label: getInitiatorVendorLabel(tracker),
      },
      vendor: {
        identifier: getVendorIdentifier(tracker),
        label: getVendorLabel(tracker),
      },
      type: tracker.type,
      max_lifetime_seconds: tracker.max_lifetime_seconds ? parseInt(tracker.max_lifetime_seconds) : 0,
      lifetime: getTrackerAgeInMonthOrDays(tracker.max_lifetime_seconds),
      lifetime_in_months_numeric: getTrackerAgeInMonthsNumeric(tracker.max_lifetime_seconds),
      scenarios: tracker.scenarios,
      ran_scenarios: tracker?.ran_scenarios,
      functional_scenario: computeFunctionalScenario(tracker.cmp?.scenario_step?.id || []),
      is_regex: tracker?.is_regex || false,
      is_exempt: !!tracker?.exemption_category_id,
      exemption_category_id: tracker?.exemption_category_id,
      purpose_ids: tracker?.purpose_ids,
      purposes: purposes.filter((purpose: Purpose) => (tracker.purpose_ids || []).includes(purpose.id)),
    } as AggregatedTrackerNew;
  }) as unknown as AggregatedTracker[];
};
