import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCounterText, getTranslatedValue, supportedLanguages } from '@didomi/helpers';
import { DidomiFiltersBarCustomEvent } from '@didomi/ui-atoms';
import {
  DidomiEmptyState,
  DidomiButton,
  DidomiTable,
  DidomiTableHeading,
  DidomiTableHeaderRow,
  DidomiTableTh,
  DidomiTableBody,
  DidomiTableRow,
  DidomiTableTd,
  DidomiPaginator,
  DidomiSkeleton,
  DidomiHintbox,
  DidomiErrorState,
  DidomiFiltersBar,
  DidomiTooltip,
  DidomiChip,
} from '@didomi/ui-atoms-react';
import { useSnackbar, useHasAccessPolicies } from '@didomi/utility-react';
import { ACCESS_POLICIES_CONFIG } from '@access';
import { PurposeListItemActionButtons, TextWithHighlighterAndTooltip, CopyWithSnackbar } from '@components';
import { usePurposes, usePaginationQueryParams, usePaginatedFilteredItems, useDeletePurpose, useGlobalPurposes } from '@hooks';
import { RemoveItemConfirmationModal, LoadingModal } from '@modals';
import { SortConfig, PurposeSortableFiled, Purpose, PurposeFilterType } from '@types';
import { getFilteredPurposes } from '@utils';

const SEARCH_FIELDS = ['id', 'sdk_id'];
const TRANSLATABLE_SEARCH_FIELDS = ['description', 'details'];

const languagesOptions = supportedLanguages.map(l => ({ value: l.code, label: l.longName }));

/**
 * Purposes Default Page
 */
export const Purposes = (): JSX.Element => {
  const [deletingPurpose, setDeletingPurpose] = useState<Purpose>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en');
  const navigate = useNavigate();
  const { displaySnackbar } = useSnackbar();
  const [sortConfig, setSortConfig] = useState<SortConfig<PurposeSortableFiled>>({ field: 'description', dir: 'asc' });
  const { isLoading, isRefetching, data: customPurposes, error: errorLoadingPurposes } = usePurposes();
  const { data: globalPurposes, isLoading: isLoadingGlobalPurposes } = useGlobalPurposes();
  const availablePurposes = useMemo(() => {
    return customPurposes?.concat(globalPurposes || []) || [];
  }, [customPurposes, globalPurposes]);

  const { search, limit, page: currPage, setSearch, setLimit, setPage: setCurrPage } = usePaginationQueryParams({ maxItems: availablePurposes.length });
  const { hasAccess: canEdit } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.EDITOR);

  const [purposesFilter, setPurposesFilter] = useState<PurposeFilterType>(PurposeFilterType.CUSTOM);

  const filteredPurposes = useMemo(() => {
    return getFilteredPurposes(availablePurposes, purposesFilter);
  }, [availablePurposes, purposesFilter]);

  const filters = useMemo(
    () => ({
      filterByPurposeType: {
        placeholder: 'Purpose type',
        defaultValue: PurposeFilterType.ALL,
        value: purposesFilter,
        options: [
          { value: PurposeFilterType.GLOBAL, label: 'Global purposes' },
          { value: PurposeFilterType.CUSTOM, label: 'Custom purposes' },
          { value: PurposeFilterType.IAB, label: 'IAB purposes' },
        ],
      },
      filterByTranslation: {
        placeholder: 'View translation',
        value: selectedLanguage,
        options: languagesOptions,
      },
    }),
    [purposesFilter, selectedLanguage],
  );

  const { data: displayPurposes, total: paginationTotal } = usePaginatedFilteredItems<Purpose, PurposeSortableFiled>(filteredPurposes, {
    search,
    searchFields: SEARCH_FIELDS,
    translatableSearchFields: TRANSLATABLE_SEARCH_FIELDS,
    limit,
    page: currPage,
    sortConfig,
  });

  const { mutate: deletePurpose, isLoading: isDeletingPurpose } = useDeletePurpose({
    onSuccess: deletedPurpose => {
      setDeletingPurpose(null);
      displaySnackbar(`Purpose "${getTranslatedValue(deletedPurpose.data.description)}" has been deleted successfully!`, { icon: 'check' });
    },
    onError: () => {
      displaySnackbar('There was an error deleting the purpose', { icon: 'danger-light', variant: 'error' });
    },
  });

  const handleFilterUpdate = (newFilterChange: DidomiFiltersBarCustomEvent<{ key: string; newValue: string | PurposeFilterType }>) => {
    const modifiedFilter = newFilterChange.detail.key;
    if (modifiedFilter === 'filterByTranslation') {
      setSelectedLanguage(newFilterChange.detail.newValue);
    }
    if (modifiedFilter === 'filterByPurposeType') {
      setPurposesFilter(newFilterChange.detail.newValue as PurposeFilterType);
    }
  };

  const handleClearFilters = () => {
    setSearch('');
    setSelectedLanguage(undefined);
    setPurposesFilter(PurposeFilterType.ALL);
  };

  const noPurposes = !availablePurposes?.length;
  const noDisplayPurposes = !displayPurposes?.length;
  const countText = getCounterText(availablePurposes?.length, 'result', 'results');
  const loadingPurposes = isLoading || isRefetching || isLoadingGlobalPurposes;

  const updateSorting = (e: CustomEvent) => {
    const newSortBy = e.detail.sortId;
    const newSortDir = newSortBy !== sortConfig.field ? 'desc' : e.detail.direction;
    setSortConfig({ dir: newSortDir, field: newSortBy ?? sortConfig.field });
  };

  const resultsText = `${paginationTotal < availablePurposes?.length ? `${paginationTotal} of ` : ''}${countText}`;

  return (
    <>
      {!noPurposes && (
        <div className="w-full h-full pt-6 flex flex-col">
          {canEdit && (
            <DidomiHintbox iconName="warning" titleText="Important" variant="warning" className="mb-m">
              Here you can find the purposes that you have created in case you would like to amend them. You must state a specified, explicit and lawful purpose that data subjects
              will be able to understand. Keep it short, keep it clear and refer to our resources center for more details about purposes.
            </DidomiHintbox>
          )}

          <div className="flex justify-end flex-wrap-reverse gap-xs items-center mb-s">
            <DidomiFiltersBar
              data-testid="filter-bar"
              className="flex-1"
              leftText={resultsText}
              filters={filters}
              placeholderTextFilter="Search a purpose"
              searchValue={search}
              collapsibleFilters={false}
              onSearchTextChange={setSearch}
              showSearch
              onClearAllFilters={handleClearFilters}
              onFilterValueChange={handleFilterUpdate}
            />

            {canEdit && (
              <DidomiButton data-tracking="add-crud-list-button" iconRight="new-create" onClick={() => navigate('/add-purpose')}>
                Add purpose
              </DidomiButton>
            )}
          </div>
          {!noDisplayPurposes && (
            <>
              <DidomiSkeleton variant="layout" isLoading={loadingPurposes}>
                <DidomiTable
                  data-testid={loadingPurposes ? 'loading-purposes-table' : 'purpose-table'}
                  data-cy={loadingPurposes ? 'loading-purposes-table' : 'purpose-table'}
                  fixedLayout
                  className="mb-4"
                  loading={loadingPurposes}
                  sortable
                  sortBy={sortConfig.field}
                  sortDirection={sortConfig.dir}
                  onSortChange={updateSorting}
                >
                  <DidomiTableHeading>
                    <DidomiTableHeaderRow>
                      <DidomiTableTh sortId="description" data-testid="sortName">
                        NAME
                      </DidomiTableTh>
                      <DidomiTableTh sortId="details" data-testid="sortDescription">
                        DESCRIPTION
                      </DidomiTableTh>
                      <DidomiTableTh sortId="is_spi">TYPE</DidomiTableTh>
                      <DidomiTableTh sortId="sdk_id" data-testid="sortSdkId">
                        SDK ID
                      </DidomiTableTh>
                      <DidomiTableTh sortId="id" data-testid="sortId">
                        PURPOSE ID (API)
                      </DidomiTableTh>
                      {canEdit && <DidomiTableTh cellAlign="right" style={{ maxWidth: 152 }}></DidomiTableTh>}
                    </DidomiTableHeaderRow>
                  </DidomiTableHeading>
                  <DidomiTableBody>
                    {displayPurposes?.map(({ id, description, details, sdk_id, organization_id, is_spi }, i) => (
                      <DidomiTableRow key={id} selectionValue={id} data-testid={id} data-cy={`purpose-row-${i}`}>
                        <DidomiTableTd>
                          <div data-skeleton={loadingPurposes} className="w-full">
                            <TextWithHighlighterAndTooltip searchText={search} text={getTranslatedValue(description, '-', selectedLanguage, !selectedLanguage)} />
                          </div>
                        </DidomiTableTd>
                        <DidomiTableTd>
                          <div data-skeleton={loadingPurposes} className="w-full">
                            <TextWithHighlighterAndTooltip searchText={search} text={getTranslatedValue(details, '-', selectedLanguage, !selectedLanguage)} />
                          </div>
                        </DidomiTableTd>
                        <DidomiTableTd>
                          <div data-skeleton={loadingPurposes}>
                            {is_spi && (
                              <DidomiTooltip content="Sensitive Personal Information">
                                <DidomiChip label="SPI" variation="basic" basic-type="info-alternative" />
                              </DidomiTooltip>
                            )}
                          </div>
                        </DidomiTableTd>
                        <DidomiTableTd>
                          <div data-skeleton={loadingPurposes} className="w-full">
                            <CopyWithSnackbar text={sdk_id}>
                              <TextWithHighlighterAndTooltip searchText={search} text={sdk_id || '-'} />
                            </CopyWithSnackbar>
                          </div>
                        </DidomiTableTd>
                        <DidomiTableTd>
                          <div data-skeleton={loadingPurposes} className="w-full">
                            <CopyWithSnackbar text={id}>
                              <TextWithHighlighterAndTooltip searchText={search} text={id || '-'} />
                            </CopyWithSnackbar>
                          </div>
                        </DidomiTableTd>
                        <DidomiTableTd cellAlign="right" style={{ maxWidth: 152 }}>
                          <PurposeListItemActionButtons purpose={{ id, organization_id }} onDeleteClicked={() => setDeletingPurpose({ id, description, details, sdk_id })} />
                        </DidomiTableTd>
                      </DidomiTableRow>
                    ))}
                  </DidomiTableBody>
                </DidomiTable>
              </DidomiSkeleton>
              <DidomiPaginator
                data-testid="purposes-paginator"
                className="self-end"
                page={currPage}
                itemCount={paginationTotal}
                size={limit}
                onPageSizeChange={setLimit}
                onPageChange={setCurrPage}
                disabled={loadingPurposes}
              />
            </>
          )}
          {noDisplayPurposes && (
            <DidomiEmptyState illustration-name="list-cannot-be-loaded" className="border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1 mb-s">
              No results
            </DidomiEmptyState>
          )}
        </div>
      )}
      {noPurposes && errorLoadingPurposes && (
        <div className="w-full h-full mt-s">
          <DidomiErrorState illustration-name="list-cannot-be-loaded" className="h-full border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1">
            <div slot="title">There was an error loading the purpose list</div>
          </DidomiErrorState>
        </div>
      )}
      {noPurposes && !isLoading && !errorLoadingPurposes && (
        <div className="w-full h-full mt-s">
          <DidomiEmptyState
            illustration-name="no-widget-yet"
            className="h-full border-1 border-dashed border-neutral-gray-5 rounded-lg py-8"
            actionName="Add purpose"
            onActionClick={() => navigate(`/add-purpose`)}
          >
            <div slot="title">You have no purposes yet</div>
          </DidomiEmptyState>
        </div>
      )}
      <RemoveItemConfirmationModal
        itemName={getTranslatedValue(deletingPurpose?.description)}
        isOpen={!!deletingPurpose && !isDeletingPurpose}
        onCancel={() => setDeletingPurpose(null)}
        onProceed={() => deletePurpose(deletingPurpose.id)}
      />
      <LoadingModal data-cy="add-purpose-saving" isOpen={isDeletingPurpose} title="We are deleting your purpose..." />
    </>
  );
};
