import React from 'react';
import { DidomiCopyText } from '@didomi/ui-atoms-react';
import { useSnackbar } from '@didomi/utility-react';

interface CopyWithSnackbarProps {
  className?: string;
  text: string;
  children: React.ReactNode;
}

export const CopyWithSnackbar = ({ className, text, children }: CopyWithSnackbarProps) => {
  const { displaySnackbar } = useSnackbar();

  return (
    <DidomiCopyText style={{ '--copy-button-padding': 0 }} className={className} text={text} onTextCopied={() => displaySnackbar('Copied to clipboard', { icon: 'check' })}>
      {children}
    </DidomiCopyText>
  );
};
