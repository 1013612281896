import { PrivacySignalsConfig } from '@didomi/cmp-generator';
import { Purpose } from '@types';

/**
 * Make sure that signals contain only purposes that are part of the config, and we don't show any leftover purposes
 * Leftover purpose is a purpose that was assigned to a signal but is not part of the config anymore(e.g. purpose was removed from the vendor)
 */
export const getSignalsConfigsWithoutLeftoverPurposes = (signalsConfigs: PrivacySignalsConfig[], selectedPurposes: Purpose[]) => {
  const selectedPurposesSdkIds = selectedPurposes.map(p => p.sdk_id);
  const isPurposeSelected = (purposeSdkId: string) => selectedPurposesSdkIds.includes(purposeSdkId);

  return signalsConfigs.map(signal => ({ ...signal, purposes: signal.purposes.filter(isPurposeSelected) }));
};
