import { ReportRequestType, ReportStatus } from '@enums';
import { Report } from '@types';
import { Report as ReportV2 } from '../types/responses/report';

export const convertReports = (reports: Report[]): ReportV2[] => {
  if (!reports?.length) return [];

  return reports.map(report => {
    return {
      id: report.id,
      created_at: (report.created || report.created_at) as unknown as string, // old type has a wrong type
      updated_at: (report.modified || report.updated_at) as unknown as string,
      status: report.status as ReportStatus,
      score: report.score,
      trackers_count: report.trackers_count,
      vendors_count: (report.vendors_count || 0) + (report.unmatched_slds_count || 0),
      request_type: report.is_cron_requested ? ReportRequestType.SCHEDULED : ReportRequestType.ON_DEMAND,
      provider: report.provider,
    };
  });
};
