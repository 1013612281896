import { parse } from 'psl';

export const getVendorIdentifierOld = requestOrTracker => {
  if (requestOrTracker?.vendor_id) return requestOrTracker.vendor_id;
  if (requestOrTracker?.url_host) return requestOrTracker.url_host;
  if (requestOrTracker?.url_sld) return requestOrTracker.url_sld;
  if (requestOrTracker?.host) return requestOrTracker.host;
  return null;
};
export const getVendorIdentifier = requestOrTracker => {
  if (requestOrTracker?.vendor?.id) return requestOrTracker.vendor.id;
  if (requestOrTracker?.host) return requestOrTracker.host;
  if (requestOrTracker?.sld) return requestOrTracker.sld;

  return getVendorIdentifierOld(requestOrTracker);
};
export const getVendorLabelOld = requestOrTracker => {
  if (requestOrTracker?.vendor_id) return requestOrTracker.vendor_name;
  if (requestOrTracker?.property_match_name) return requestOrTracker.property_match_name;
  if (requestOrTracker?.url_host && requestOrTracker?.url_sld && requestOrTracker.url_host.includes(requestOrTracker.url_sld)) {
    return requestOrTracker.url_host;
  }
  if (requestOrTracker?.url_sld) return requestOrTracker.url_sld;
  if (requestOrTracker?.url_host) return requestOrTracker.url_host;
  if (requestOrTracker?.host) return requestOrTracker.host;
  return null;
};
export const getVendorLabel = requestOrTracker => {
  if (requestOrTracker?.vendor?.id) return requestOrTracker.vendor.name;
  if (requestOrTracker?.property_match_name) return requestOrTracker.property_match_name;
  if (requestOrTracker?.host && requestOrTracker?.sld && requestOrTracker.host.includes(requestOrTracker.sld)) {
    return requestOrTracker.host;
  }
  if (requestOrTracker?.sld) return requestOrTracker.sld;
  if (requestOrTracker?.host) return requestOrTracker.host;

  return getVendorLabelOld(requestOrTracker);
};
export const getInitiatorVendorIdentifierOld = requestOrTracker => {
  if (requestOrTracker.initiator_vendor_id) return requestOrTracker.initiator_vendor_id;
  if (requestOrTracker?.initiator_url_host) return requestOrTracker.initiator_url_host;
  if (requestOrTracker?.initiator_url_sld) return requestOrTracker.initiator_url_sld;
  return null;
};
export const getInitiatorVendorIdentifier = requestOrTracker => {
  if (requestOrTracker?.initiator?.vendor?.id) return requestOrTracker.initiator.vendor.id;
  if (requestOrTracker?.initiator?.url_host) return requestOrTracker.initiator.url_host;
  if (requestOrTracker?.initiator?.url_sld) return requestOrTracker.initiator.url_sld;
  // handle PropertyTracker entity
  // Note: PropertyTracker entity has no initiator or vendor object but
  // initiator_vendor_id and vendor_id at the root level
  // so, we have to fall back here when we're not dealing with an actual tracker
  // but with a property tracker setting
  return getInitiatorVendorIdentifierOld(requestOrTracker);
};
export const getInitiatorVendorLabelOld = requestOrTracker => {
  if (requestOrTracker.initiator_vendor_id) return requestOrTracker.initiator_vendor_name;
  if (requestOrTracker.initiator_property_match_name) return requestOrTracker.initiator_property_match_name;
  if (requestOrTracker?.initiator_url_host && requestOrTracker?.initiator_url_sld && requestOrTracker.initiator_url_host.includes(requestOrTracker.initiator_url_sld)) {
    return requestOrTracker.initiator_url_host;
  }
  if (requestOrTracker?.initiator_url_sld) return requestOrTracker.initiator_url_sld;
  if (requestOrTracker?.initiator_url_host) return requestOrTracker.initiator_url_host;
  return null;
};
export const getInitiatorVendorLabel = requestOrTracker => {
  if (requestOrTracker.initiator?.vendor?.id) return requestOrTracker.initiator.vendor.name;
  if (requestOrTracker.initiator_property_match_name) return requestOrTracker.initiator_property_match_name;
  if (requestOrTracker?.initiator?.url_host && requestOrTracker?.initiator?.url_sld && requestOrTracker.initiator?.url_host.includes(requestOrTracker.initiator?.url_sld)) {
    return requestOrTracker.initiator.url_host;
  }
  if (requestOrTracker?.initiator?.url_sld) return requestOrTracker.initiator.url_sld;
  if (requestOrTracker?.initiator?.url_host) return requestOrTracker.initiator.url_host;

  return getInitiatorVendorLabelOld(requestOrTracker);
};
export const getVendorHostsDomain = (hosts: string[]): string[] => {
  const domainSet = new Set<string>();

  (hosts || []).forEach(host => {
    const cleanHost = host.replace(/^\*\./, '');
    const { domain } = parse(cleanHost);
    if (domain) {
      domainSet.add(domain);
    }
  });

  return Array.from(domainSet);
};
export const nodesAndEdgesFromRequestsAndTrackers = (requests: any[], trackers: any[]) => {
  const nodeColors = ['#D1EFFA', '#21c6b2', 'rgb(245, 192, 90)', 'rgb(238, 128, 91)'];
  const Node = overrides => {
    return {
      id: null,
      label: null,
      value: 0,
      url_hosts: [],
      host_is_property: false,
      initiated_from_property_requests_count: 0,
      initiated_from_vendors_requests_count: 0,
      initiated_from: [],
      initiated_to_property_requests_count: 0,
      initiated_to_vendors_requests_count: 0,
      initiated_to: [],
      initiated_trackers: [],
      associated_trackers: [],
      namespaces: {},
      color: '#cccccc',
      shape: 'circle',
      scenarios: [],
      ...overrides,
    };
  };

  const nodesObject = {};
  const edgesObject = {};
  const trackersPerInitiatorVendorObject = {};
  const trackersPerVendorObject = {};

  // setup vendors and initiators
  for (let i = 0; i < requests.length; i++) {
    const request = requests[i];
    const nodeIdentifier = getVendorIdentifierOld(request);
    const nodeLabel = getVendorLabelOld(request);
    const initiatorNodeIdentifier = getInitiatorVendorIdentifierOld(request);

    // Todo use Map or Set
    if (!nodesObject[nodeIdentifier]) {
      nodesObject[nodeIdentifier] = Node({
        id: nodeIdentifier,
        label: nodeLabel,
        namespaces: request['vendor_namespaces'],
        scenarios: request?.scenarios,
        shape: 'square',
      });
    }

    nodesObject[nodeIdentifier].owned_domains = getVendorHostsDomain(request['vendor_hosts']);
    nodesObject[nodeIdentifier].vendor_links = request['vendor_links'];

    !nodesObject[nodeIdentifier].url_hosts.includes(request.url_host) && nodesObject[nodeIdentifier].url_hosts.push(request.url_host);
    nodesObject[nodeIdentifier].value += parseInt(request.count_aggregate_items);
    nodesObject[nodeIdentifier].host_is_property = !!request.host_is_property;
    nodesObject[nodeIdentifier].initiated_from_property_requests_count += request.initiator_is_property ? parseInt(request.count_aggregate_items) : 0;
    nodesObject[nodeIdentifier].initiated_from_vendors_requests_count += request.initiator_is_property ? 0 : parseInt(request.count_aggregate_items);
    if (!nodesObject[nodeIdentifier].initiated_from.includes(initiatorNodeIdentifier)) {
      nodesObject[nodeIdentifier].initiated_from.push(initiatorNodeIdentifier);
    }
    // coloring
    let distance_from_property = 3; // worst case, only vendors called this
    if (nodesObject[nodeIdentifier].initiated_from_property_requests_count > 0) {
      distance_from_property -= 1;
    }
    if (nodesObject[nodeIdentifier].initiated_from_vendors_requests_count == 0) {
      distance_from_property -= 1;
    }
    if (nodesObject[nodeIdentifier].host_is_property) {
      distance_from_property = 0;
    }
    nodesObject[nodeIdentifier].color = nodeColors[distance_from_property];

    const edgeIdentifier = nodeIdentifier + '>' + initiatorNodeIdentifier;
    if (!edgesObject[edgeIdentifier]) {
      edgesObject[edgeIdentifier] = {
        from: initiatorNodeIdentifier,
        to: nodeIdentifier,
        dashes: false,
        width: 0.02,
        color: '#ccc',
        arrows: { to: { enabled: true, scaleFactor: 0.5, type: 'arrow' } },
      };
    }
  }

  // adding bidirectional initiated_to
  for (let i = 0; i < requests.length; i++) {
    const request = requests[i];
    const nodeIdentifier = getVendorIdentifierOld(request);

    const initiatorNodeIdentifier = getInitiatorVendorIdentifierOld(request);
    const initiatorNodeLabel = getInitiatorVendorLabelOld(request);

    if (!nodesObject[initiatorNodeIdentifier]) {
      nodesObject[initiatorNodeIdentifier] = Node({
        id: initiatorNodeIdentifier,
        label: initiatorNodeLabel,
        namespaces: request['vendor_namespaces'],
      });
    } else {
      nodesObject[initiatorNodeIdentifier].initiated_to_property_requests_count += request.host_is_property ? parseInt(request.count_aggregate_items) : 0;
      nodesObject[initiatorNodeIdentifier].initiated_to_vendors_requests_count += request.host_is_property ? 0 : parseInt(request.count_aggregate_items);
      if (!nodesObject[initiatorNodeIdentifier].initiated_to.includes(nodeIdentifier)) {
        nodesObject[initiatorNodeIdentifier].initiated_to.push(nodeIdentifier);
      }
    }
  }

  // trackers
  for (let i = 0; i < trackers.length; i++) {
    const tracker = trackers[i];
    const trackerInitiatorVendorIdentifier = getInitiatorVendorIdentifierOld(tracker);
    const trackerVendorIdentifier = getVendorIdentifierOld(tracker);
    if (!trackersPerInitiatorVendorObject[trackerInitiatorVendorIdentifier]) {
      trackersPerInitiatorVendorObject[trackerInitiatorVendorIdentifier] = {
        initiated_trackers: [],
      };
    }
    if (!trackersPerVendorObject[trackerVendorIdentifier]) {
      trackersPerVendorObject[trackerVendorIdentifier] = {
        associated_trackers: [],
      };
    }
    trackersPerInitiatorVendorObject[trackerInitiatorVendorIdentifier].initiated_trackers.push({
      name: tracker.name,
      host: tracker.host,
      type: tracker.type,
      initiator_url_host: tracker.initiator_url_host,
      lifetime: tracker.lifetime,
      scenarios: tracker.scenarios,
      functional_scenario: tracker.functional_scenario,
    });
    trackersPerVendorObject[trackerVendorIdentifier].associated_trackers.push({
      name: tracker.name,
      host: tracker.host,
      type: tracker.type,
      initiator_url_host: tracker.initiator_url_host,
      lifetime: tracker.lifetime,
      scenarios: tracker.scenarios,
      functional_scenario: tracker.functional_scenario,
    });
  }
  for (const vendorIdentifier in trackersPerInitiatorVendorObject) {
    if (nodesObject[vendorIdentifier]) {
      nodesObject[vendorIdentifier].initiated_trackers = [
        ...nodesObject[vendorIdentifier].initiated_trackers,
        ...trackersPerInitiatorVendorObject[vendorIdentifier].initiated_trackers,
      ];
      nodesObject[vendorIdentifier].shape = 'triangle';
    }
  }
  /* vendor identifiers in trackers 'hosts' are scope, meaning that EVERY vendor that has access to these hosts is associated with the tracker */
  for (const vendorIdentifier in trackersPerVendorObject) {
    // handling for cookie domains on an sld scope
    for (const nodeIdentifier in nodesObject) {
      // nodeIdentifier is the sld or host or one that that endsWith the domain so this tracker is valid in this scope
      if (nodeIdentifier.endsWith('.' + vendorIdentifier) || nodeIdentifier == vendorIdentifier) {
        const nodeObject = nodesObject[nodeIdentifier];
        nodeObject.associated_trackers = [...nodeObject.associated_trackers, ...trackersPerVendorObject[vendorIdentifier].associated_trackers];
        nodeObject.shape = nodeObject.initiated_trackers.length ? 'triangle' : 'square';
      } else {
        // if not a match on the nodeIdentifier (can also be a vendor_id), check if domain matches any of the associated url_hosts from the vendor associated requests
        for (const url_host of nodesObject[nodeIdentifier].url_hosts) {
          if (url_host.endsWith('.' + vendorIdentifier) || url_host == vendorIdentifier) {
            const nodeObject = nodesObject[nodeIdentifier];
            nodeObject.associated_trackers = [...nodeObject.associated_trackers, ...trackersPerVendorObject[vendorIdentifier].associated_trackers];
            nodeObject.shape = nodeObject.initiated_trackers.length ? 'triangle' : 'square';
            break;
          }
        }
      }
    }
  }
  return { nodes: nodesObject, edges: edgesObject };
};
