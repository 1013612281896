import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ReportOutputKey } from '@enums';
import { AggregatedTracker } from '@types';
import { AggregatedVendor } from 'interfaces';
import { useAxiosWithLogOut } from '../../useAxiosWithInterceptors.hook';

type ReportOutputMap = {
  [ReportOutputKey.AGGREGATED_VENDORS]: AggregatedVendor[];
  [ReportOutputKey.AGGREGATED_TRACKERS]: AggregatedTracker[];
};

type UseS3OutputOptions = UseQueryOptions<ReportOutputMap[ReportOutputKey], AxiosError> & {};

export const useS3Output = (reportId: string, key: ReportOutputKey, url: string, options: UseS3OutputOptions = {}) => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetch = async (): Promise<ReportOutputMap[ReportOutputKey]> => {
    const response = await axiosWithInterceptors.get<ReportOutputMap[ReportOutputKey]>(url, {
      headers: {
        Authorization: undefined,
        'Content-Type': 'application/json',
      },
      params: {},
    });

    return response.data;
  };

  return useQuery<ReportOutputMap[ReportOutputKey], AxiosError>(['report-output', reportId, key], fetch, options);
};
