/* istanbul ignore file */
import { TRANSLATED_SCENARIOS_MAP_OLD } from '@constants';
import { AggregatedTracker, AggregatedVendor, Cookie, Request } from '@types';

/**
 * Filters and concatenates succeeded scenario keys from a tracker, a request or a vendor into a string.
 * @returns {string} A comma-separated string of translated scenario keys.
 * @param entity
 */
export const getRanScenariosOld = (entity: Partial<Cookie> | Partial<Request> | Partial<AggregatedTracker> | Partial<AggregatedVendor>): string => {
  const succeededScenarios = (entity?.ran_scenarios || []).filter(scenario => scenario.status === 'SUCCEEDED');

  if (!succeededScenarios.length) {
    return '-';
  }

  const scenarios = succeededScenarios.map(scenario => TRANSLATED_SCENARIOS_MAP_OLD[scenario.key] || scenario.key).sort((a, b) => a.localeCompare(b));

  return scenarios.join(', ');
};
