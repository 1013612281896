/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from 'react-router-dom';
import React, { useState, useMemo } from 'react';
import { DidomiIconButton, DidomiSkeleton, DidomiMenu, DidomiMenuItem, DidomiButton, DidomiModal, DidomiModalHeader, DidomiModalActions } from '@didomi/ui-atoms-react';
import { useSPARouter } from '@didomi/utility-react';
import { useGeneratePropertyReport, useReportExport, useRemoveProperty } from '@hooks';
import { ExportType, ReportInfo, Report } from '@types';
import { getLastAnalyzedReport, didLastReportRunInPastHour } from '@utils';
import { Property } from 'types/responses/property';

interface Props {
  menuKey: number;
  hasComplianceReportEditAccess: boolean;
  property: Property;
  hideRemove?: boolean;
  hideDetail?: boolean;
  reportId?: string;
  isLoading?: boolean;
  onReportCreated?: () => void;
  onPropertyDeleted?: () => void;
}
const ComplianceReportMenuAction = ({
  menuKey,
  hasComplianceReportEditAccess,
  property,
  reportId,
  hideRemove = false,
  hideDetail = false,
  isLoading = false,
  onReportCreated,
  onPropertyDeleted,
}: Props) => {
  const navigate = useNavigate();
  const { navigateTo } = useSPARouter();
  const [textModal, setTextModal] = useState('new');

  const { mutateAsync: removeProperty, isLoading: isRemovingProperty } = useRemoveProperty({});
  const { mutateAsync: askNewReportForProperty } = useGeneratePropertyReport({});

  const exportReportId = useMemo(() => {
    return reportId || getLastAnalyzedReport(property)?.id;
  }, [property, reportId]);

  const { exportReport, isLoading: generatingReport, reportInfo, reportType } = useReportExport({ reportId: exportReportId, property });

  const scrapperCoolDownActive = useMemo(() => {
    return didLastReportRunInPastHour(property);
  }, [property]);

  const [isOpen, setIsOpen] = useState(false);

  const handleDialogChange = (e: CustomEvent) => {
    if (isOpen && !e.detail) {
      setIsOpen(e.detail);
    }
  };
  const openDialog = modal => {
    setTextModal(modal);
    setIsOpen(true);
  };

  const handleRemove = async () => {
    setIsOpen(false);
    await removeProperty({ property_id: property.id });
    onPropertyDeleted?.();
  };

  const redirectToOldComplianceSpaUpdatePage = () => {
    return navigateTo(`agnostik-compliance-report/edit-domain/${property.id}`);
  };

  const redirectToManageTrackerPolicyPage = () => {
    return navigateTo(`agnostik-compliance-report/domain/${property.id}/manage-tracker-policy`);
  };

  const handleNewReportDemand = async () => {
    setIsOpen(false);
    await askNewReportForProperty(property);
    onReportCreated?.();
  };

  return (
    <div>
      <DidomiSkeleton variant="layout" isLoading={isLoading}>
        <div className="flex flex-row items-center gap-2">
          {hasComplianceReportEditAccess && (
            <DidomiIconButton id={`menu-button-${menuKey}`} variant="rounded" icon="submenu" data-skeleton data-testid="menu-action-button"></DidomiIconButton>
          )}
          <DidomiIconButton id={`menu-button-download-${menuKey}`} variant="rounded" icon="download" data-skeleton data-testid="menu-action-download-button"></DidomiIconButton>
          <DidomiMenu for={`menu-button-${menuKey}`} variant="main" data-skeleton placement="bottom">
            {hasComplianceReportEditAccess && (
              <>
                <DidomiMenuItem icon-name="new-create" onClick={() => openDialog('new')} data-cy="request-report" data-testid="request-report">
                  Request new report
                </DidomiMenuItem>
                <DidomiMenuItem disabled={!exportReportId} icon-name="settings" onClick={() => redirectToManageTrackerPolicyPage()} data-cy="manage-tracker-policy">
                  Manage tracker policy
                </DidomiMenuItem>
                <div className="w-full h-[1px] bg-neutral-gray-5 mb-xs mt-xs"></div>
                <DidomiMenuItem icon-name="edit" onClick={() => redirectToOldComplianceSpaUpdatePage()} data-cy="request-report">
                  Edit this domain
                </DidomiMenuItem>
                {!hideRemove && (
                  <DidomiMenuItem icon-name="delete" onClick={() => openDialog('delete')} data-cy="delete-domain" data-testid="delete-domain">
                    Remove this domain
                  </DidomiMenuItem>
                )}
              </>
            )}
          </DidomiMenu>
          <DidomiMenu for={`menu-button-download-${menuKey}`} variant="main" data-skeleton placement="bottom">
            <DidomiMenuItem
              disabled={!exportReportId || generatingReport}
              onClick={() => exportReport({ info: ReportInfo.VENDOR, type: ExportType.CSV })}
              data-cy="download-vendor-csv"
              data-testid="download-vendor-csv"
            >
              <span>{generatingReport && reportInfo === ReportInfo.VENDOR && reportType === ExportType.CSV ? 'Generating vendors CSV' : 'Download vendors CSV'}</span>
            </DidomiMenuItem>
            <DidomiMenuItem
              disabled={!exportReportId || generatingReport}
              onClick={() => exportReport({ info: ReportInfo.VENDOR, type: ExportType.EXCEL })}
              data-cy="download-vendor-excel"
              data-testid="download-vendor-excel"
            >
              <span>{generatingReport && reportInfo === ReportInfo.VENDOR && reportType === ExportType.EXCEL ? 'Generating vendors Excel' : 'Download vendors Excel'}</span>
            </DidomiMenuItem>
            <DidomiMenuItem
              disabled={!exportReportId || generatingReport}
              onClick={() => exportReport({ info: ReportInfo.VENDOR, type: ExportType.PDF })}
              data-cy="download-vendor-pdf"
              data-testid="download-vendor-pdf"
            >
              <span>{generatingReport && reportInfo === ReportInfo.VENDOR && reportType === ExportType.PDF ? 'Generating vendors PDF' : 'Download vendors PDF'}</span>
            </DidomiMenuItem>
            <div className="w-full h-[1px] bg-neutral-gray-5 mb-xs mt-xs"></div>
            <DidomiMenuItem
              disabled={!exportReportId || generatingReport}
              onClick={() => exportReport({ info: ReportInfo.TRACKER, type: ExportType.CSV })}
              data-cy="download-tracker-csv"
              data-testid="download-tracker-csv"
            >
              <span>{generatingReport && reportInfo === ReportInfo.TRACKER && reportType === ExportType.CSV ? 'Generating trackers CSV' : 'Download trackers CSV'}</span>
            </DidomiMenuItem>
            <DidomiMenuItem
              disabled={!exportReportId || generatingReport}
              onClick={() => exportReport({ info: ReportInfo.TRACKER, type: ExportType.EXCEL })}
              data-cy="download-tracker-excel"
              data-testid="download-tracker-excel"
            >
              <span>{generatingReport && reportInfo === ReportInfo.TRACKER && reportType === ExportType.EXCEL ? 'Generating trackers Excel' : 'Download trackers Excel'}</span>
            </DidomiMenuItem>
            <DidomiMenuItem
              disabled={!exportReportId || generatingReport}
              onClick={() => exportReport({ info: ReportInfo.TRACKER, type: ExportType.PDF })}
              data-cy="download-tracker-pdf"
              data-testid="download-tracker-pdf"
            >
              <span>{generatingReport && reportInfo === ReportInfo.TRACKER && reportType === ExportType.PDF ? 'Generating trackers PDF' : 'Download trackers PDF'}</span>
            </DidomiMenuItem>

            <>
              <div className="w-full h-[1px] bg-neutral-gray-5 mb-xs mt-xs"></div>
              <DidomiMenuItem
                disabled={!exportReportId || generatingReport}
                onClick={() => exportReport({ info: ReportInfo.COOKIE_POLICY, type: ExportType.JSON })}
                data-cy="download-cookie-policy-json"
                data-testid="download-cookie-policy-json"
              >
                <span>{generatingReport ? 'Generating cookie policy' : 'Download cookie policy'}</span>
              </DidomiMenuItem>
            </>
          </DidomiMenu>
          {!hideDetail && (
            <DidomiButton size="small" disabled={!exportReportId} onClick={() => navigate(`/domain/${property.id}`)} data-skeleton data-cy="detail-card-btn">
              Details
            </DidomiButton>
          )}
        </div>
        <DidomiModal isOpen={isOpen} onOpenChange={handleDialogChange} attachTo="overlay-container-test-app" returnFocusAfterClose={false}>
          <DidomiModalHeader
            illustrationName="warning-modal"
            modalTitle={
              textModal === 'new' ? (scrapperCoolDownActive ? 'You can not run a new scan' : 'You are going to launch a new report.') : 'You are going to remove a domain.'
            }
            modalSubTitle="Pay attention"
            modalDescription={
              textModal === 'new'
                ? scrapperCoolDownActive
                  ? 'Your last report was launched less than an hour ago, you must wait at least an hour before launching a new one'
                  : 'The request will be processed in some time.'
                : ''
            }
            variant="warning"
          ></DidomiModalHeader>

          <DidomiModalActions>
            <DidomiButton variant="secondary" onClick={() => setIsOpen(false)} disabled={isRemovingProperty}>
              Close
            </DidomiButton>
            <DidomiButton
              style={{ display: scrapperCoolDownActive && textModal === 'new' ? 'none' : 'inline-block' }}
              onClick={textModal === 'new' ? handleNewReportDemand : handleRemove}
              disabled={isRemovingProperty}
              className="min-w-[215px]"
              data-cy="accept-btn"
              data-testid="accept-btn"
            >
              Confirm the request
            </DidomiButton>
          </DidomiModalActions>
        </DidomiModal>
      </DidomiSkeleton>
    </div>
  );
};

export { ComplianceReportMenuAction };
