import { useMemo } from 'react';
import { Regulation, PrivacySignalsConfig, PrivacySignalSection, NoticeConfig } from '@didomi/cmp-generator';
import { PreferencesCategoriesConfig, Purpose } from '@types';
import {
  getPrivacySignalConfigUniqueName,
  getPurposesFromCategories,
  getPurposesMappedToSignals,
  getRegulationConfigGppStateId,
  getSignalConfigsAvailableForMappingForStateId,
  getSignalsConfigsWithoutLeftoverPurposes,
  getSyncedGppSignalsSettings,
  getUniqueMappableSignals,
  getUniqueSignalNamesForStateId,
  sortSignalsByNamespace,
} from '@utils';
import { usePrivacySignals } from '../privacy-signals/usePrivacySignals.hook';

export const useGppPrivacySignalsState = ({
  regulationId,
  aggregatedRegConfig,
  selectedPurposes,
  enabled,
}: {
  regulationId: string;
  aggregatedRegConfig: NoticeConfig;
  selectedPurposes: Purpose[];
  enabled: boolean;
}) => {
  const { data: signals = [], isLoading } = usePrivacySignals(regulationId, 'gpp', { enabled });

  const gppStateId = useMemo(() => {
    if (!enabled || !aggregatedRegConfig || !signals.length) return null;
    return getRegulationConfigGppStateId(aggregatedRegConfig, signals, regulationId as Regulation);
  }, [aggregatedRegConfig, signals, regulationId, enabled]);

  const gppSettings = useMemo(() => {
    if (!enabled || !aggregatedRegConfig) return null;
    return aggregatedRegConfig?.regulation_settings.gpp;
  }, [aggregatedRegConfig, enabled]);

  // Privacy signals that are stored in the regulation settings
  const storedPrivacySignals = useMemo(() => {
    if (!gppSettings) return [];
    return gppSettings.privacySignals || [];
  }, [gppSettings]);

  // Privacy signals that should be configured for the current settings
  const visiblePrivacySignals = useMemo(() => {
    if (!enabled || !signals.length) return [];

    const signalsToDisplay = getSignalConfigsAvailableForMappingForStateId(signals, storedPrivacySignals, gppStateId);
    const signalsWithoutLeftoverPurposes = getSignalsConfigsWithoutLeftoverPurposes(signalsToDisplay, selectedPurposes);

    return signalsWithoutLeftoverPurposes.sort(sortSignalsByNamespace);
  }, [storedPrivacySignals, signals, gppStateId, enabled, selectedPurposes]);

  const mappedPurposes = useMemo(() => getPurposesMappedToSignals(visiblePrivacySignals), [visiblePrivacySignals]);

  const sharingNoticeValue = useMemo(() => {
    if (!gppSettings || !signals.length || !getUniqueSignalNamesForStateId(signals, gppStateId)?.has(PrivacySignalSection.SharingNotice)) return null;
    return gppSettings.sharingNoticeSignalValue;
  }, [gppSettings, gppStateId, signals]);

  // Return privacy signals that are synced with available mappable signals
  const getSyncedPrivacySignals = () => {
    const uniqueMappableSignals = getUniqueMappableSignals(signals);
    return getSyncedGppSignalsSettings(storedPrivacySignals, uniqueMappableSignals);
  };

  // Return updated privacy signals after user performs purpose-to-signal mapping
  const getPrivacySignalsAfterMapping = (updatedSignal: PrivacySignalsConfig) => {
    const signalUniqueNameToUpdate = getPrivacySignalConfigUniqueName(updatedSignal);
    return storedPrivacySignals.map(s => (getPrivacySignalConfigUniqueName(s) === signalUniqueNameToUpdate ? updatedSignal : s));
  };

  // Return updated privacy signals when available purposes changes - to make sure that signals contain only purposes that are part of the categories
  const getPrivacySignalsAfterPurposesChange = (updatedCategories: PreferencesCategoriesConfig) => {
    const selectedPurposes = getPurposesFromCategories(updatedCategories);
    const purposesIdsSet = new Set(selectedPurposes.map(p => p.purposeId));
    return storedPrivacySignals.map(signal => ({ ...signal, purposes: signal.purposes.filter(purposeSdkId => purposesIdsSet.has(purposeSdkId)) }));
  };

  return {
    isLoading,
    gppStateId,
    signals,
    visiblePrivacySignals,
    mappedPurposes,
    sharingNoticeValue,
    getSyncedPrivacySignals,
    getPrivacySignalsAfterMapping,
    getPrivacySignalsAfterPurposesChange,
  };
};
