import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, PropertyTrackerItem } from '@types';

type UsePropertyTrackerSettingsArgs = {
  propertyId?: string;
  enabled?: boolean;
};

export const usePropertyCookiePolicies = ({ propertyId, enabled = true }: UsePropertyTrackerSettingsArgs = {}) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const fetchPropertyCookiePolicies = async (propertyId: string) => {
    if (propertyId) {
      const response = await axiosWithInterceptors.get<PaginatedResponse<PropertyTrackerItem>>(`${apiRoutes.crProperties}/${propertyId}/cookie-policies`, {
        params: {
          organization_id: organizationId,
        },
      });
      return response.data;
    }
  };

  const query = useQuery<PaginatedResponse<PropertyTrackerItem>, AxiosError>(['property-cookie-policies', propertyId], () => fetchPropertyCookiePolicies(propertyId), {
    refetchInterval: false,
    enabled,
  });

  const fetch = (propertyId: string) =>
    queryClient.fetchQuery<PaginatedResponse<PropertyTrackerItem>, AxiosError>(['property-cookie-policies', propertyId], () => fetchPropertyCookiePolicies(propertyId));

  return {
    ...query,
    fetch,
  };
};
