import { AxiosError } from 'axios';
import { useQueryClient, UseQueryOptions } from 'react-query';
import { ReportOutputKey } from '@enums';
import { usePropertyReport } from '@hooks';
import { AggregatedTracker } from '@types';
import { AggregatedVendor } from 'interfaces';
import { useS3Output } from './useS3Output.hook';

const REPORT_STALE_TIME = 4 * 60 * 1000; // 4 minutes

type ReportOutputMap = {
  [ReportOutputKey.AGGREGATED_VENDORS]: AggregatedVendor[];
  [ReportOutputKey.AGGREGATED_TRACKERS]: AggregatedTracker[];
};

type UseReportOutputOptions = UseQueryOptions<ReportOutputMap[ReportOutputKey], AxiosError> & {};

export const useReportOutput = <T>(
  reportId: string,
  key: ReportOutputKey,
  options: UseReportOutputOptions = {},
): {
  data: T[];
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const cachedResult: T[] | undefined = queryClient.getQueryData(['report-output', reportId, key]);

  const { data: report, isLoading: isReportLoading } = usePropertyReport({
    reportId,
    enabled: !cachedResult,
    staleTime: REPORT_STALE_TIME,
    isLegacy: false,
  });

  const { data: result, isLoading: isS3OutputLoading } = useS3Output(reportId, key, report?.outputs?.[key], {
    ...options,
    enabled: !!report?.outputs?.[key],
  });

  return {
    data: cachedResult || (result as T[]),
    isLoading: isReportLoading || isS3OutputLoading,
  };
};
