import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import React, { useState } from 'react';
import { useActiveOrganization, useFeatureFlag, useReleaseFlag } from '@didomi/utility-react';
import { ComplianceReportUnmatchedVendorDomainsOld, ComplianceReportUnmatchedVendorDomains } from '@components';
import { homeRoute, settingsRoute } from '@constants';
import { useAccessPolicies } from '@hooks';
import { AddDomainLayout, MainLayout, ReportDetailLayout, UnmatchVendorDomainsLayout } from '@layouts';
import { AddDomain, AlertingSettings, ComplianceReportDetail, ComplianceReports, EditDomain, MatchDomainsToVendorPage, OldManageTrackerPolicy } from './../../pages/index';
import { SettingsActionButton } from '../../components/SettingsActionButton/SettingsActionButton';
import { SecondaryLayout } from '../../layouts/SecondaryLayout/SecondaryLayout';
import { ManageTrackerPolicy } from '../ManageTrackerPolicy/ManageTrackerPolicy';

const App = () => {
  const [isQuotaExceeded, setIsQuotaExceeded] = useState(false);
  const [hasAdvancedComplianceMonitoringAccess] = useFeatureFlag('compliance_report_v2');
  const [isReportsV2Enabled] = useReleaseFlag('reports_v2');
  const [isTrackerPolicyV2Enabled] = useReleaseFlag('trackers_policy_v2');

  const { hasAdminAccess } = useAccessPolicies();
  const { organizationId } = useActiveOrganization();

  return (
    <BrowserRouter basename={`${organizationId}/agnostik-compliance-report`}>
      <Routes>
        <Route
          path="/"
          element={
            <MainLayout TitleAction={hasAdvancedComplianceMonitoringAccess && hasAdminAccess && SettingsActionButton} isQuotaExceeded={isQuotaExceeded}>
              <ComplianceReports setIsQuotaExceeded={setIsQuotaExceeded} />
            </MainLayout>
          }
        ></Route>
        <Route
          path="/domain/:propertyId"
          element={
            <ReportDetailLayout>
              <ComplianceReportDetail />
            </ReportDetailLayout>
          }
        ></Route>

        <Route
          path="/domain/:propertyId/match-unknown-domains/:reportId"
          element={
            <UnmatchVendorDomainsLayout>
              <ComplianceReportUnmatchedVendorDomains />
            </UnmatchVendorDomainsLayout>
          }
        ></Route>

        <Route
          path="/domain/:propertyId/match-unknown-domains/legacy/:reportId"
          element={
            <UnmatchVendorDomainsLayout>
              <ComplianceReportUnmatchedVendorDomainsOld />
            </UnmatchVendorDomainsLayout>
          }
        ></Route>

        <Route path="/domain/:propertyId/match-unknown-domains-to-vendor/:reportId" element={<MatchDomainsToVendorPage />}></Route>
        <Route
          path="/add-domain"
          element={
            <AddDomainLayout>
              <AddDomain />
            </AddDomainLayout>
          }
        ></Route>
        <Route
          path="/edit-domain/:id"
          element={
            <AddDomainLayout>
              <EditDomain />
            </AddDomainLayout>
          }
        ></Route>
        <Route
          path={settingsRoute}
          element={
            <SecondaryLayout titleText={'Email Alerts Settings'} backText={'Back to Compliance Reports'} backPath={homeRoute} fullWidth={true}>
              <AlertingSettings />
            </SecondaryLayout>
          }
        ></Route>
        <Route
          path="/domain/:propertyId/manage-tracker-policy"
          element={
            <SecondaryLayout titleText={'Manage Tracker Policy'} backText={'Back to Compliance Reports'} backPath={homeRoute} fullWidth={true}>
              {isReportsV2Enabled || isTrackerPolicyV2Enabled ? <ManageTrackerPolicy /> : <OldManageTrackerPolicy />}
            </SecondaryLayout>
          }
        ></Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export { App };
