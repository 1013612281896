import { RegulationFilterStatus, RegulationUiModel } from '@types';

export const getFilteredRegulations = (regulations: RegulationUiModel[], selectedRegulationsHash: Record<string, any>, statusFilter: RegulationFilterStatus) => {
  return regulations.filter(regulation => {
    if (statusFilter === RegulationFilterStatus.ALL) return true;
    if (statusFilter === RegulationFilterStatus.ENABLED && selectedRegulationsHash[regulation.id]) return true;
    if (statusFilter === RegulationFilterStatus.DISABLED && !selectedRegulationsHash[regulation.id]) return true;

    return false;
  });
};
