import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { apiRoutes } from '@constants';
import { ReportStatus, ReportWithOptions } from '@enums';
import { Report } from '@types';
import { convertLegacyReportStatus } from '@utils';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

type UsePropertyReportArgs = {
  reportId?: string | number;
  enabled?: boolean;
  isLegacy?: boolean;
} & UseQueryOptions<Report, AxiosError>;

export const usePropertyReport = ({ reportId, enabled = true, isLegacy = true, ...options }: UsePropertyReportArgs) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const fetchPropertyReportByReportId = async reportId => {
    if (reportId) {
      const url = isLegacy ? apiRoutes.reports : apiRoutes.reportsV2;

      const response = await axiosWithInterceptors.get<Report>(`${url}/${reportId}`, {
        params: {
          organization_id: organizationId,
          ...(!isLegacy && { $with: [ReportWithOptions.OUTPUTS] }),
        },
      });

      response.data.status = convertLegacyReportStatus(response.data.status as ReportStatus);

      return response.data;
    }
  };

  const results = useQuery<Report, AxiosError>([`report`, reportId], () => fetchPropertyReportByReportId(reportId), {
    refetchInterval: false,
    enabled,
    ...options,
  });

  const fetch = (reportId: string) => queryClient.fetchQuery<Report, AxiosError>([`report`, reportId], () => fetchPropertyReportByReportId(reportId));

  return {
    ...results,
    fetch,
  };
};
