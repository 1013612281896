import { useParams } from 'react-router-dom';
import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiButton } from '@didomi/ui-atoms-react';
import { useSPARouter } from '@didomi/utility-react';
import { useQueryClient } from 'react-query';
import { useSessionStorage } from 'usehooks-ts';

interface IProps {
  setSelectedSuggestedOption: Function;
}
const NoVendorSuggestions = ({ setSelectedSuggestedOption }: IProps) => {
  const { navigateTo } = useSPARouter();
  const { reportId, propertyId } = useParams();
  const [storedUnknownVendorDomains] = useSessionStorage('unknown-vendors-domains-' + reportId, null);
  const queryClient = useQueryClient();

  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/agnostik-compliance-report');

  const handleShowAllVendor = () => {
    const evt = {
      detail: {
        key: 'suggestion',
        newValue: 'false',
      },
    };
    setSelectedSuggestedOption(evt);
  };
  const handleCreateNewVendor = async () => {
    await queryClient.resetQueries();
    navigateTo(
      // TODO only use .id after the migration
      `/data-manager/add-vendor?reportDomain=${reportId}[${propertyId}]&vendorKey=${
        storedUnknownVendorDomains?.[0]?.url_sld || storedUnknownVendorDomains?.[0]?.id || 'domain.com'
      }&fromPath=${window.location.pathname}`,
    );
  };
  return (
    <>
      <div className="w-full h-[624px] bg-neutral-gray-1 flex items-center flex-col rounded-lg">
        <div className="w-full h-[624px] bg-neutral-gray-1 flex items-center flex-col rounded-lg">
          <img alt="No match logo" className="w-[220px] mt-[140px] mb-xs" src={`${ASSETS_URL}/assets/No-result-found.png`} />
          <span className="text-center text-primary-blue-4 mb-xxxs body-big-semibold">We have no suggestions for those matchs!</span>
          <span className="text-center text-primary-blue-6 mb-xxxs text-h3">You can manually look through all the vendors, or you can go for a new vendor.</span>
          <div className="flex justify-around mt-xs">
            <DidomiButton variant="secondary" className="mr-xs" onClick={handleShowAllVendor}>
              <span style={{ fontSize: '14px' }}>Show all vendors</span>
            </DidomiButton>
            <DidomiButton variant="secondary" onClick={handleCreateNewVendor}>
              Match it to new vendor
            </DidomiButton>
          </div>
        </div>
      </div>
    </>
  );
};

export { NoVendorSuggestions };
