import React from 'react';
import { DidomiTableRow, DidomiTableTd } from '@didomi/ui-atoms-react';
import { TRANSLATED_SCENARIOS_MAP_OLD } from '@constants';
import { getRanScenariosOld } from '@utils';
import { AggregatedVendor } from 'types/vendor';
import { TruncatedTextWithTooltip } from '../TruncatedTextWithTooltip/TruncatedTextWithTooltip';

interface Props {
  vendors: AggregatedVendor[];
  onVendorClick: (vendorIdentifier: string, mergedVendorsIdentifier: string[]) => void;
  displayOnlyName?: boolean;
  isLoadingNodes: boolean;
}
const ComplianceReportDetailTableVendorRow = ({ vendors, onVendorClick, displayOnlyName = false, isLoadingNodes }: Props) => {
  return (
    <>
      {vendors.map(vendor => {
        return (
          <DidomiTableRow data-skeleton key={vendor.identifier}>
            <DidomiTableTd>
              <button
                data-testid="vendor-button"
                className={`outline-none text-left contents ${isLoadingNodes && 'cursor-wait'}`}
                title={isLoadingNodes ? 'Processing associations...' : undefined}
                onClick={() => !isLoadingNodes && onVendorClick(vendor.identifier, vendor.merged_identifiers || [])}
              >
                <TruncatedTextWithTooltip value={vendor.label} className="underline underline-offset-2" />
              </button>
            </DidomiTableTd>
            {!displayOnlyName && (
              <>
                <DidomiTableTd>
                  <span>{vendor?.tcfId || 'Not in TCF'}</span>
                </DidomiTableTd>
                <DidomiTableTd>
                  <TruncatedTextWithTooltip value={vendor.legalBasis || '-'} />
                </DidomiTableTd>
                <DidomiTableTd>
                  <span>{vendor?.requests || 0}</span>
                </DidomiTableTd>
                <DidomiTableTd>
                  <TruncatedTextWithTooltip value={TRANSLATED_SCENARIOS_MAP_OLD[vendor.functional_scenario]} />
                </DidomiTableTd>
                <DidomiTableTd>
                  <TruncatedTextWithTooltip value={getRanScenariosOld(vendor)} />
                </DidomiTableTd>
              </>
            )}
          </DidomiTableRow>
        );
      })}
    </>
  );
};

export { ComplianceReportDetailTableVendorRow };
