import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import React, { useState, cloneElement } from 'react';
import { DidomiSecondaryHeader, DidomiBackButtonLink, DidomiBottomBar, DidomiButton } from '@didomi/ui-atoms-react';
import { useSessionStorage } from 'usehooks-ts';
import { SelectUnmatchedVendorDomainsGuard } from '@modals';
interface IUnmatchVendorDomainsLayoutProps {
  titleText?: string;
  backText?: string;
  children: React.ReactElement;
}

/**
 * UnmatchVendorDomainsLayout Layout
 */
export const UnmatchVendorDomainsLayout = ({ backText, children }: IUnmatchVendorDomainsLayoutProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const matchingVendorHost = searchParams.get('vendorKey');
  const reportProvider = searchParams.get('report_provider');
  const [selectedVendorHosts, setSelectedVendorHosts] = useState([]);
  const { propertyId, reportId } = useParams();

  const [, setStoredUnknownVendorDomains] = useSessionStorage('unknown-vendors-domains-' + reportId, null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClick = () => {
    setIsModalOpen(false);
  };

  const handleNavigateBack = () => {
    navigate('/domain/' + propertyId + '?activeTab=issue');
  };

  const handleAddSelectedDomains = () => {
    if (selectedVendorHosts.length < 1) {
      setIsModalOpen(true);
    } else {
      setStoredUnknownVendorDomains(selectedVendorHosts);
      const params = new URLSearchParams(searchParams);
      if (reportProvider) {
        params.set('report_provider', reportProvider);
      }
      const queryString = params.toString() ? `?${params.toString()}` : '';
      navigate(`/domain/${propertyId}/match-unknown-domains-to-vendor/${reportId}${queryString}`);
    }
  };

  return (
    <section className="p-12 pb-6 box-border !flex flex-col">
      <SelectUnmatchedVendorDomainsGuard isOpen={isModalOpen} handleClick={handleModalClick} title="Domain required."></SelectUnmatchedVendorDomainsGuard>
      <DidomiSecondaryHeader titleText={'Unmatched vendors'}>
        <div slot="description">
          These vendors domains were detected in your compliance reports. They don&apos;t match to any vendor in our database, please manually match them in order to increase the
          accuracy of the reports.
        </div>
        <div slot="back-button">
          <DidomiBackButtonLink text={backText} className="cursor-pointer" onClick={handleNavigateBack} />
        </div>
      </DidomiSecondaryHeader>
      <div className="w-full mt-s h-full flex h-auto">
        <div className="w-full"> {cloneElement(children, { setSelectedVendorHosts, matchingVendorHost })}</div>
      </div>
      <DidomiBottomBar className="-mx-l -mb-m flex-shrink-0 h-[120px]" isOpen={true} variant="light" closable={false}>
        <div slot="actions" className="flex items-center gap-4 mt-xs">
          <DidomiButton variant="secondary" onClick={handleNavigateBack}>
            Back
          </DidomiButton>
          <DidomiButton disabled={!selectedVendorHosts?.length} data-testId="step1" className="flex items-center" onClick={() => handleAddSelectedDomains()}>
            Find a match for selected domains
          </DidomiButton>
        </div>
      </DidomiBottomBar>
    </section>
  );
};
