import { ReportStatus } from '@enums';

const STATUS_MAP: Record<string, ReportStatus> = {
  ready: ReportStatus.PENDING,
  running: ReportStatus.QUEUED,
  failure: ReportStatus.FAILED,
  analyzed: ReportStatus.SUCCESS,
};

/**
 * Converts legacy report status strings to the new ReportStatus enum values
 *
 * Legacy status mapping:
 * - 'ready' -> PENDING (report is ready to be processed)
 * - 'running' -> QUEUED (report is in the processing queue)
 * - 'failure' -> FAILURE (report processing failed)
 * - 'analyzed' -> SUCCESS (report was successfully analyzed)
 * - any other status -> status
 *
 * @param status - The legacy status string to convert
 * @returns The corresponding ReportStatus enum value
 */
export const convertLegacyReportStatus = (status: ReportStatus): ReportStatus => {
  return STATUS_MAP[status] || status;
};
