import { UserOptions } from 'jspdf-autotable';

export const REPORT_PDF_CONFIG: Partial<UserOptions> = {
  margin: { horizontal: 5, top: 5 },
  alternateRowStyles: {
    cellWidth: 50,
  },
  columnStyles: {
    Date: { cellWidth: 30 },
    'Is tag': { cellWidth: 15, halign: 'center' },
    'Tag type': { cellWidth: 25 },
    'TCF id': { cellWidth: 15, halign: 'center' },
    'Legal Basis': { cellWidth: 30, halign: 'center' },
    'User behaviour': { cellWidth: 35 },
    'Cookie domain': { cellWidth: 35 },
    'Initiator URL': { cellWidth: 35 },
    'Ran scenarios': { cellWidth: 35 },
    Vendor: { cellWidth: 25 },
    'Lifetime (in seconds)': { halign: 'center', cellWidth: 40 },
    'Initiator Vendor': { cellWidth: 25 },
    'Cookie name': { cellWidth: 35 },
    Party: { cellWidth: 25, halign: 'center' },
    'Initiator Legal Basis': { cellWidth: 30, halign: 'center' },
    'Request url': { cellWidth: 35, halign: 'center' },
    'Initiator url': { cellWidth: 35, halign: 'center' },
    'Page url': { cellWidth: 30, halign: 'center' },
    sample: { cellWidth: 30, halign: 'center' },
  },
  rowPageBreak: 'avoid',
};
