import { ReportStatus } from '@enums';
import { Property } from 'types/responses/property';
import { orderReportsByDate } from './orderReportsByDate';

/**
 * Return the last analyzed report from the property
 * @param property
 * @returns
 */
export const getLastAnalyzedReport = (property: Pick<Property, 'latest_reports'>) => {
  if (!property?.latest_reports?.length) return null;

  const orderedReports = orderReportsByDate(property.latest_reports);

  return orderedReports.find(report => [ReportStatus.SUCCESS, ReportStatus.PARTIAL].includes(report.status)) || null;
};
