import { MatchedHost } from './matched-host.type';
import { Scenario } from './tracker';

export enum LegalBasis {
  LEGITIMATE_INTEREST = 'Legitimate interest',
  CONSENT_ONLY = 'Consent only',
  LEGITIMATE_INTEREST_CONSENT_ONLY = 'Legitimate interest, Consent only',
  UNKNOWN = '-',
}

export type CollectedVendors = {
  canonical_metadata_partner_id?: string | null;
  canonical_metadata_partner_name?: string | null;
  functional_namespace?: string | null;
  id?: string | null;
  key?: string | null;
  name?: string | null;
  namespaces?: null | string;
  notes?: string | null;
  organization_id?: string;
  url_sld?: string | null;
  url_host: string | null;
  vendor_iabv2_id?: null | number;
  vendor_tcfv2_data?: null | number;
  nameOrKey?: string | null;
  url?: string;
};

export type VendorLink = {
  website: string;
  privacypolicy: string;
  optout: string;
  terms: string;
  dpa: string;
  protection: string;
  subprocessors: string;
};

export enum VendorLinkType {
  dpa = 'Data Processing',
  optout = 'Opt-out',
  privacypolicy = 'Privacy Policy',
  protection = 'Applicable protection',
  subprocessors = 'List of Subconstructors',
  terms = 'Terms & Conditions',
  website = 'Website',
}

export type VendorLinkReadable = {
  label: string;
  value: string;
};
export interface Partner {
  vendor: any;
  url?: string;
  id?: string;
  name?: string | { [language: string]: string };
  website?: string;
  organization_id: string;
  is_initiated_by_property?: number;
  is_property?: number;
  key?: string;
  url_sld?: string;
  url_host: string;
  cookies?: any[];
  ntags?: number;
  initiators?: string[];
  security?: Security3;
  tcfv2_data_json?: TCFV2JsonData;
  vendor_country_name?: string;
  vendor_country_protection?: string;
  vendor_iabv2_id?: number;
  vendor_location?: string;
  vendor_tcfv2_data?: null;
  vendor_vendorcategory_name?: string;
  vendor_vigilance_rating?: number;
  nameOrKey?: string | { [language: string]: string };
  iabTcfId?: number;
  sdk_id?: string | number;
  namespaces?:
    | VendorNamespaces
    | null
    | {
        iab?: string;
        iab2?: string;
        didomi?: string;
        custom?: string;
        google?: string;
      };
  deprecated?: boolean;
  default_purposes_id?: string[];
  legitimate_interest_purposes_id?: string[];
  spi_purposes_id?: string[];
  address?: { [language: string]: string } | string;
  country_id?: string;
  protection_id?: string;
  links?: VendorLink;
  links_readable?: VendorLinkReadable[];
  tags?: [string];
  contact?: string;
  website_optout?: string;
  hosts?: [string];
  url_patterns?: [string];
  urls?: [string];
  taxonomy_id?: string;
  category_id?: string;
  country?: string;
  created_at?: Date;
  updated_at?: Date;
  version?: number;
  default_purposes_name?: string[];
  legitimate_interest_purposes_name?: string[];
  cookie_max_duration?: number;
  cookie_max_duration_unit?: string;
  uses_non_cookie_access?: boolean;
  filterType?: string;
  notes?: string;
  type?: string;
}
// Partner == vendor
export interface PatnerRequestBody {
  address?: { [language: string]: string };
  notes?: string;
  category_id?: string;
  contact?: { [language: string]: string };
  cookie_max_duration?: number;
  cookie_max_duration_unit?: string;
  country_id?: string;
  default_purposes_id?: string[];
  legitimate_interest_purposes_id?: string[];
  links?: {
    dpa?: string;
    optout?: string;
    privacypolicy: string;
    protection?: string;
    subprocessors?: string;
    terms?: string;
    website?: string;
  };
  hosts?: string[];
  name: string;
  namespaces?: { custom: string };
  organization_id: string;
  protection_id?: string;
  taxonomy_id?: string;
  uses_non_cookie_access?: boolean;
  spi_purposes_id?: string[];
  last_metadata_partner_event_time?: Date;
  last_metadata_partner_event_type?: 'io.didomi.metadata.partner_updated' | 'io.didomi.metadata.partner_created';
}
export interface Vendor {
  functional_scenario: Scenario['key'];
  ran_scenarios: Scenario[];
  id?: string;
  vendor_id?: string;
  name?: string;
  vendor_name?: string;
  website?: string;
  is_initiated_by_property?: number;
  is_property?: number;
  key?: string;
  url_sld?: string;
  url_host?: string;
  cookies?: any[];
  ntags?: string;
  initiators?: string[];
  security?: Security3;
  tcfv2_data_json?: TCFV2JsonData;
  vendor_country_name?: string;
  vendor_country_protection?: string;
  vendor_iabv2_id?: number;
  vendor_location?: string;
  vendor_tcfv2_data?: null;
  vendor_vigilance_rating?: number;
  property_match_name?: string;
}
interface VendorNamespaces {
  didomi?: string;
  custom?: string;
  iab?: number;
  iab2?: number;
  google?: number;
}

export interface VendorGraphNodeTracker {
  name: string;
  host: string;
  type: string;
  initiator_url_host: string;
  lifetime: number;
  functional_scenario: Scenario['key'];
}

export interface VendorGraphEdges {
  [key: string]: VendorGraphEdge;
}
export interface Arrows {
  to: {
    enabled: boolean;
    scaleFactor: number;
    type: string;
  };
}
export interface VendorGraphEdge {
  from: string;
  to: string;
  dashes: boolean;
  width: number;
  color: string;
  arrows: Arrows;
}
export interface VendorGraphNodes {
  [key: string]: VendorGraphNode;
}
export interface VendorLinks {
  privacypolicy: string;
  website: string;
  fuzzy_website?: string | null;
  fuzzy_privacypolicy?: string | null;
}
export interface VendorGraphNode {
  id: string;
  label: string;
  value: number;
  url_hosts: string[];
  host_is_property: false;
  initiated_from_property_requests_count: number;
  initiated_from_vendors_requests_count: number;
  initiated_from: string[];
  initiated_to_property_requests_count: number;
  initiated_to_vendors_requests_count: number;
  initiated_to: string[];
  initiated_trackers: VendorGraphNodeTracker[];
  associated_trackers: VendorGraphNodeTracker[];
  functional_scenario: Scenario['key'];
  scenarios: Scenario[];
  namespaces: VendorNamespaces;
  color: string;
  shape: string;
  vendor_links?: VendorLinks;
  owned_domains?: string[];
}
export interface vendorGraph {
  id: string;
  key: number | string;
  initiator_slds: string[];
  initiator_is_property: number;
  initiator_key: number | string;
  initiator_ntags: number;
  tcfv2_data_json: TcfData;
  name: string;
  website: string;
  cookies: any[];
  ntags: number;
  initiators: any[];
  security: Security3;
}

export interface TcfData {}

export interface AggregatedVendor {
  // TODO remove old properties
  isUnknow: boolean;
  name?: string | null;
  tcfIdNumber?: number;
  tcfId: number | null | string;
  legalBasis?: LegalBasis | null;
  country: string | null;
  countryAdequation: string | null;
  requests: number | null;
  tcfv2_data_json: TCFV2JsonData;
  identifier: string;
  label: string;
  suggested?: boolean;
  url_sld?: string;
  url_host?: string;
  functional_scenario: Scenario['key'];
  ran_scenarios: Scenario[];
  merged_identifiers?: string[];

  // New properties
  report_id: string; // ID of the report for which this vendor was found
  property_id: string; // ID of the property for which this vendor was found
  organization_id: string; // ID of the organization for which this vendor was found
  // eslint-disable-next-line max-len
  id: string; // ID of the vendor, it could be the vendor id (ie: vendor-1) if matched otherwise it's the host (ie: api.google.com)
  request_count: number; // Number of requests for the Vendor
  cmp?: {
    scenario_step: {
      id: string[]; // All the distinct succeeded scenarios steps where the vendor was found
      status: string;
    };
    scenario: {
      id: string[]; //All the distinct scenarios where the vendor was found
    };
  };
  partner?: {
    id: string; // Id of the Vendor
    name: string; // Name of the Vendor
    matched_hosts: MatchedHost[]; // URL hosts that have matched the partner
    functional_namespace: string; // Functional namespace of the Vendor
    country?: string | null; // Country name of the Vendor
    namespaces?: Record<string, string>;
    links?: VendorLinks;
    canonical_metadata_partner_id?: string;
    tcf?: {
      default_purposes_id?: string[];
      spi_purposes_id?: string[];
      legitimate_interest_purposes_id?: string[];
    };
  } | null;
  created_at: string;
}

export interface TCFV2JsonData {
  cookieMaxAgeSeconds: number;
  cookieRefresh: boolean;
  deviceStorageDisclosureUrl: string;
  features: number[];
  flexiblePurposes: number[];
  id: number;
  legIntPurposes: number[];
  name: string;
  policyUrl: string;
  purposes: number[];
  specialFeatures: [];
  specialPurposes: number[];
  usesCookies: boolean;
  usesNonCookieAccess: boolean;
}
export interface Initiators {
  property: boolean;
  vendors: Vendor[];
}

export interface Security2 {
  ssl: number;
  protocols: number;
}

export interface Vendors {
  security: Security2;
}

export interface Security3 {
  protocols: Protocols;
  certificate: Certificate;
}

export interface Protocols {
  latest: boolean;
  forwardsecrecy: boolean;
}

export interface Certificate {
  valid: boolean;
  trusted: boolean;
}
