import React, { useState, cloneElement, useMemo } from 'react';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import { DidomiSecondaryHeader, DidomiBackButtonLink, DidomiButton, DidomiHintbox } from '@didomi/ui-atoms-react';
import { useActiveOrganization } from '@didomi/utility-react';
import { useSessionStorage } from 'usehooks-ts';
import { useBackNavigation } from '@hooks';
import { SelectUnmatchedVendorDomainsGuard } from '@modals';
import { DraftVendor } from '@types';

interface IMatchingVendorLayoutProps {
  titleText?: string;
  backText?: string;
  children: React.ReactElement;
}

/**
 * MatchingVendor Layout
 */
const MatchingVendorLayout = ({ titleText, backText, children }: IMatchingVendorLayoutProps): JSX.Element => {
  const navigateBack = useBackNavigation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const matchingVendorHost = searchParams.get('vendorKey');
  const { vendorId } = useParams();
  const templateId = searchParams.get('templateId');
  const reportDomain = searchParams.get('reportDomain');
  const { organizationId } = useActiveOrganization();
  const isEditingVendor = searchParams.get('update');
  const isCreatingVendor = searchParams.get('creation');
  const [selectedVendorHosts, setSelectedVendorHosts] = useState([]);
  const [storedDraftVendorFormData, setStoredDraftVendorFormData] = useSessionStorage<DraftVendor>('draft-vendor-' + organizationId, null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClick = () => {
    setIsModalOpen(false);
  };

  const handleNavigateBack = () => {
    const fromPath = searchParams.get('fromPath');
    if (isEditingVendor) {
      navigateBack(`/vendor/${vendorId}`);
    } else if (isCreatingVendor) {
      navigateBack(`/add-vendor`);
    } else {
      // Use navigate here to keep the fromPath and avoiding trigger the
      // navigateToAbsolute function from navigateBack
      // The fromPath is needed to allow /add-vendor to redirect to the CMP Vendors sync (SVL) or ACM (CR) frompath
      reportDomain
        ? navigate(`/add-vendor?vendorKey=${matchingVendorHost}&reportDomain=${reportDomain}&fromPath=${fromPath}`)
        : navigate(`/add-vendor?vendorKey=${matchingVendorHost}&templateId=${templateId}&fromPath=${fromPath}`);
    }
  };
  const handleAddSelectedDomains = () => {
    if (selectedVendorHosts.length < 1) {
      setIsModalOpen(true);
    } else {
      // Ignored because i can not mock selectedVendorHosts from state
      /* istanbul ignore next */
      const newDradVendorFormData = storedDraftVendorFormData;
      if (!newDradVendorFormData) {
        navigateBack(`/add-vendor`);
      }
      // TODO use only .id after the migration
      const hosts = [...newDradVendorFormData.vendor.hosts, ...selectedVendorHosts.map(elm => elm.url_host || elm.id)];
      newDradVendorFormData.vendor.hosts = hosts.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      setStoredDraftVendorFormData(newDradVendorFormData);
      handleNavigateBack();
    }
  };

  const existingVendorName = useMemo(() => {
    if (storedDraftVendorFormData?.vendor?.name?.length) {
      return matchingVendorHost;
    } else {
      return 'the vendor you are creating';
    }
  }, [matchingVendorHost, storedDraftVendorFormData]);
  return (
    <section className="p-12 pb-6 h-full box-border !flex flex-col">
      <SelectUnmatchedVendorDomainsGuard isOpen={isModalOpen} handleClick={handleModalClick} title="Domain required."></SelectUnmatchedVendorDomainsGuard>
      <DidomiSecondaryHeader titleText={matchingVendorHost ? `${titleText} : ${matchingVendorHost}` : `Match unknow domains owned by the vendor`}>
        <div slot="back-button">
          <DidomiBackButtonLink text={backText} class="cursor-pointer" onClick={handleNavigateBack} />
        </div>
        <div slot="actions">
          <DidomiButton onClick={handleAddSelectedDomains}>Match selected domains</DidomiButton>
        </div>
      </DidomiSecondaryHeader>
      <DidomiHintbox
        className="mt-s"
        title-text={`Your Didomi compliance report has detected the following unmatched vendor domains. Please select any that are owned by ${existingVendorName}`}
      ></DidomiHintbox>
      <div className="w-full mt-s h-full flex">
        <div className="w-full"> {cloneElement(children, { setSelectedVendorHosts, matchingVendorHost })}</div>
      </div>
    </section>
  );
};

export { MatchingVendorLayout };
