import React from 'react';
import { NoticeConfig } from '@didomi/cmp-generator';
import { DidomiHintbox, DidomiIcon, DidomiRadio, DidomiRadioGroup, DidomiSelectionCardItem, DidomiTooltip } from '@didomi/ui-atoms-react';

interface CustomizationRegulationOptionsCTVFocusProps {
  aggregatedRegConfig: NoticeConfig;
  disabled: boolean;
  handleCtvFocusOnAgreeAndCloseChange: (newValueEvent: CustomEvent<string>) => void;
}

const CustomizationRegulationOptionsCTVFocus = ({ aggregatedRegConfig, disabled, handleCtvFocusOnAgreeAndCloseChange }: CustomizationRegulationOptionsCTVFocusProps) => {
  return (
    <div className="text-body-small text-primary-pink-5 mb-xxs">
      <hr className="h-0 border-t-1 border-neutral-gray-3 my-s" />
      <h3 className="font-semibold mb-xs">Remote control focus options</h3>

      <DidomiHintbox className="mb-xxs" iconName="warning-blue">
        <p className="text-primary-blue-6">This option is only available for web-based CTV environments.</p>
      </DidomiHintbox>
      <DidomiRadioGroup
        className="w-full"
        data-testid="remote-control-cursor-focus-options"
        value={aggregatedRegConfig?.ctv_focus_on_agree_and_close ? 'ctv-focus-on-agree-and-close' : 'default'}
        orientation="vertical"
        hideErrorMessage
        onValueChange={handleCtvFocusOnAgreeAndCloseChange}
        disabled={disabled}
      >
        <DidomiSelectionCardItem>
          <DidomiRadio dimmed={aggregatedRegConfig?.ctv_focus_on_agree_and_close} data-testid="default-option" value="default">
            Notice first layer text
          </DidomiRadio>
        </DidomiSelectionCardItem>
        <DidomiSelectionCardItem>
          <div className="flex justify-between items-center">
            <DidomiRadio dimmed={!aggregatedRegConfig?.ctv_focus_on_agree_and_close} data-testid="agree-and-close-option" value="ctv-focus-on-agree-and-close">
              Agree &amp; Close button
            </DidomiRadio>
            <DidomiTooltip variant="helper" contentElementId="darkPatternHelper" className="flex ml-xxxs">
              <DidomiIcon className="w-xs" name="warning"></DidomiIcon>
            </DidomiTooltip>
          </div>
          <div id="darkPatternHelper" hidden>
            Pay attention to this behavior as it could be considered as a <strong>dark pattern</strong>.
          </div>
        </DidomiSelectionCardItem>
      </DidomiRadioGroup>
    </div>
  );
};

export default CustomizationRegulationOptionsCTVFocus;
