import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { apiRoutes } from '@constants';
import { PropertyWithOptions } from '@enums';
import { PaginatedResponse, SortConfig } from '@types';
import { convertLegacyReportStatus } from '@utils';
import { Property } from 'types/responses/property';
import { useAxiosWithLogOut } from '../../useAxiosWithInterceptors.hook';
import { usePaginationQueryParams } from '../../usePaginationQueryParams.hook';

type UsePropertiesOptions = UseQueryOptions<PaginatedResponse<Property>, AxiosError> & {
  with?: PropertyWithOptions[];
  limit?: number;
  skip?: number;
  sort?: SortConfig<keyof Property>;
};

export const useProperties = (options: UsePropertiesOptions = {}) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const searchQueryKeys = ['name', 'website'];

  // Get pagination params from query params
  const { limit, page, skip, search, setLimit, setPage, setSearch } = usePaginationQueryParams({
    debounceSearch: true,
  });

  const fetch = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<Property>>(apiRoutes.propertiesV2, {
      params: {
        organization_id: organizationId,
        $limit: options.limit || limit || 1000,
        $skip: skip || 0,
        $with: options.with || [],
        ...(search && {
          $or: {
            ...searchQueryKeys.map(key => ({ [key]: { $like: search } })),
          },
        }),
        ...(options.sort && { [`$sort[${options.sort.field}]`]: options.sort.dir === 'asc' ? 1 : -1 }),
      },
    });

    response.data.data.forEach(property => {
      property.latest_reports?.forEach(report => {
        report.status = convertLegacyReportStatus(report.status);
      });
    });

    return response.data;
  };

  return {
    ...useQuery<PaginatedResponse<Property>, AxiosError>(['properties', organizationId, limit, skip, options.with, options.sort, search], fetch, options),
    paginator: {
      page,
      limit,
      search,
      setPage,
      setLimit,
      setSearch,
    },
  };
};
