import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { AggregatedTracker, PaginatedResponse, PropertyTrackerItem } from '@types';

type UsePatchPropertyTrackersArgs = {
  propertyId: string;
  trackers: Partial<PropertyTrackerItem>[];
};

/**
 * Send patch request to assign purposes or exemption category to selected trackers
 * */
export const usePatchPropertyTrackers = (options?: UseMutationOptions<AxiosResponse<PropertyTrackerItem[]>, AxiosError, UsePatchPropertyTrackersArgs>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const patchPropertyTrackers = async (data: UsePatchPropertyTrackersArgs) => {
    return await axiosWithInterceptors.patch<PropertyTrackerItem[]>(`${apiRoutes.crProperties}/${data.propertyId}/trackers`, data.trackers, {
      params: { organization_id: organizationId },
    });
  };

  return useMutation(patchPropertyTrackers, {
    ...options,
    onSuccess: (updatedPropertyTrackers: AxiosResponse<PropertyTrackerItem[]>, variables, context) => {
      const { propertyId } = variables;
      // fetch current property tracker policy
      const propertyCookiePoliciesGet: PaginatedResponse<AggregatedTracker> = queryClient.getQueryData(['property-cookie-policies', propertyId]);

      if (propertyCookiePoliciesGet) {
        const trackersById = new Map(propertyCookiePoliciesGet?.data.map((tracker: AggregatedTracker) => [tracker.id, tracker]));
        // for each updated property tracker,
        // apply property tracker settings to the corresponding tracker (by id)
        updatedPropertyTrackers?.data.forEach((updatedPropertyTracker: PropertyTrackerItem) => {
          const currentTracker: AggregatedTracker = trackersById.get(updatedPropertyTracker.id);

          if (currentTracker?.id) {
            trackersById.set(currentTracker.id, {
              ...currentTracker,
              exemption_category_id: updatedPropertyTracker.exemption_category_id,
              purpose_ids: updatedPropertyTracker.purpose_ids,
            });
          } else {
            trackersById.set(updatedPropertyTracker.id, updatedPropertyTracker as AggregatedTracker);
          }
        });

        const updatedItems = Array.from(trackersById.values());

        // update the current property tracker policy
        // with the up-to-date trackers data
        queryClient.setQueryData(['property-cookie-policies', propertyId], {
          ...propertyCookiePoliciesGet,
          data: updatedItems,
          total: updatedItems.length,
        });
      }
      options?.onSuccess(updatedPropertyTrackers, variables, context);
    },
  });
};
