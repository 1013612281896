import React, { useMemo } from 'react';
import { RegulationConfigDefaults, Regulation, getDefaultToggleInstructions } from '@didomi/cmp-generator';
import { produce } from '@didomi/helpers';
import { DidomiTextInput, DidomiTextarea, DidomiRadioGroup } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies, useCustomFeature } from '@didomi/utility-react';
import { NoticeClosingRadio, NoticeClosingSwitch } from '@components';
import { useCustomizationRegulationConfig, useCustomizationStepOptions, useEditLocalConsentNoticeConfig, useLocalConsentNoticeConfig, useLocalRegulationConfig } from '@hooks';
import { ACCESS_POLICIES_CONFIG, INPUT_DEBOUNCE_TIME } from '@utils';

interface CustomizationSecondLayerProps {
  noticeId: string;
  regulationId: Regulation;
}

// Purposes
export const CustomizationSecondLayer = ({ noticeId, regulationId }: CustomizationSecondLayerProps) => {
  const { data: localConsentNoticeConfig, isLoading: loadingNoticeConfig } = useLocalConsentNoticeConfig(noticeId);
  const {
    hasPreferencesEnableAllButtons,
    hasPreferencesEnableBulkActionOnPurposes,
    hasPreferencesAgree,
    hasPreferencesDisagree,
    hasPreferencesViewAllPartners,
    hasPreferencesAgreeToAll,
    hasPreferencesDisagreeToAll,
    hasPreferencesSave,
    hasPreferencesSubText,
    hasUserInteractionsToggleButtons,
  } = useCustomizationRegulationConfig(regulationId, localConsentNoticeConfig);

  const { trafficLanguage } = useCustomizationStepOptions({ defaultTraffic: localConsentNoticeConfig?.config?.languages?.default });
  const { mutateAsync: updateLocalConfig } = useEditLocalConsentNoticeConfig(localConsentNoticeConfig?.id);
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);
  const { regulationConfig, regulationConfigIndex } = useLocalRegulationConfig(noticeId, regulationId);
  const [orgHasUserInteractionToggleButtons] = useCustomFeature('widgets-notices-toggles');
  const preferencesContent = regulationConfig?.config?.preferences?.content;

  const combineLiAndConsentPlaceholder = useMemo(() => {
    if (!getDefaultToggleInstructions) {
      return;
    }
    return getDefaultToggleInstructions()[trafficLanguage];
  }, [trafficLanguage]);

  const handleMainTextChange = (newText: string) => {
    if (!preferencesContent?.text?.en && !newText) /* istanbul ignore next */ return;
    updateLocalConfig(
      produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].config.preferences.content.text.${trafficLanguage}`, newText || undefined),
    );
  };

  const handlePreferenceTitleChange = (newPreferenceTitle: string) => {
    if (!preferencesContent?.title?.en && !newPreferenceTitle) /* istanbul ignore next */ return;
    updateLocalConfig(
      produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].config.preferences.content.title.${trafficLanguage}`, newPreferenceTitle || undefined),
    );
  };

  const handleEnableAllButtonChange = (enableButtonChange: CustomEvent<string>) => {
    const enableButton = enableButtonChange.detail === 'selected';
    updateLocalConfig(produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].config.preferences.enableAllButtons`, enableButton));
  };

  const handleEnableBulkButtonChange = (enableButtonChange: CustomEvent<string>) => {
    const enableButton = enableButtonChange.detail === 'selected';
    if (!regulationConfig?.config?.notice?.enableBulkActionOnPurposes && !enableButton) /* istanbul ignore next */ return;
    updateLocalConfig(produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].config.notice.enableBulkActionOnPurposes`, enableButton));
  };

  const handleViewAllPartnersChange = (newPreferenceTitle: string) => {
    if (!preferencesContent?.viewAllPartners?.en && !newPreferenceTitle) /* istanbul ignore next */ return;
    updateLocalConfig(
      produce(
        localConsentNoticeConfig,
        `regulation_configurations[${regulationConfigIndex}].config.preferences.content.viewAllPartners.${trafficLanguage}`,
        newPreferenceTitle || undefined,
      ),
    );
  };

  const handleAgreeChange = (newText: string) => {
    if (!preferencesContent?.agree?.en && !newText) /* istanbul ignore next */ return;
    updateLocalConfig(
      produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].config.preferences.content.agree.${trafficLanguage}`, newText || undefined),
    );
  };

  const handleDisagreeChange = (newText: string) => {
    if (!preferencesContent?.disagree?.en && !newText) /* istanbul ignore next */ return;
    updateLocalConfig(
      produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].config.preferences.content.disagree.${trafficLanguage}`, newText || undefined),
    );
  };

  const handleAgreeToAllChange = (newText: string) => {
    if (!preferencesContent?.agreeToAll?.en && !newText) /* istanbul ignore next */ return;
    updateLocalConfig(
      produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].config.preferences.content.agreeToAll.${trafficLanguage}`, newText || undefined),
    );
  };

  const handleDisagreeToAllChange = (newText: string) => {
    if (!preferencesContent?.disagreeToAll?.en && !newText) /* istanbul ignore next */ return;
    updateLocalConfig(
      produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].config.preferences.content.disagreeToAll.${trafficLanguage}`, newText || undefined),
    );
  };

  const handleSaveChange = (newText: string) => {
    if (!preferencesContent?.save?.en && !newText) /* istanbul ignore next */ return;
    updateLocalConfig(
      produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].config.preferences.content.save.${trafficLanguage}`, newText || undefined),
    );
  };

  const handleSubTextChange = (newText: string) => {
    if (!preferencesContent?.subText?.en && !newText) /* istanbul ignore next */ return;
    updateLocalConfig(
      produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].config.preferences.content.subText.${trafficLanguage}`, newText || undefined),
    );
  };

  const handleControlTypeChange = ({ detail: value }: CustomEvent<'toggles' | 'buttons'>) => {
    updateLocalConfig(produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].preferences_control_type`, value));
  };

  const handleConsentAndLIButtonChange = ({ detail: value }: CustomEvent<'selected' | 'not-selected'>) => {
    updateLocalConfig(produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].preferences_combine_li_and_consent`, value === 'selected'));
  };

  const handleTextForInstructionsChange = ({ detail: value }: CustomEvent<string>) => {
    const translatableString = localConsentNoticeConfig.regulation_configurations[regulationConfigIndex].preferences_content_instructions || {};
    translatableString[trafficLanguage] = value !== '' ? value : undefined;
    updateLocalConfig(produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].preferences_content_instructions`, translatableString));
  };

  const hasPurposes = hasPreferencesEnableAllButtons || hasPreferencesEnableBulkActionOnPurposes;
  const hasButtons =
    hasPreferencesAgree || hasPreferencesDisagree || hasPreferencesViewAllPartners || hasPreferencesAgreeToAll || hasPreferencesDisagreeToAll || hasPreferencesSave;
  const showCombineLiAndConsentToggles = hasUserInteractionsToggleButtons && orgHasUserInteractionToggleButtons && regulationConfig?.preferences_control_type === 'toggles';

  const preferencesControlType = regulationConfig?.preferences_control_type || 'buttons';

  const isPurposeViewBulkActionDisabled = regulationId === 'gdpr' && regulationConfig?.config?.app?.vendors?.iab?.enabled;
  const isCTVPlatform = localConsentNoticeConfig?.platform === 'ctv';

  return (
    <div data-testid="customization-second-layer" data-loading={loadingNoticeConfig} className="flex flex-col gap-xs">
      {hasUserInteractionsToggleButtons && orgHasUserInteractionToggleButtons && (
        <>
          <div className="text-body-normal font-semibold text-primary-pink-5">User interaction</div>
          <div className="text-body-normal font-semibold text-primary-blue-6">Choose your Calls to Action for purpose and vendor consent choices: </div>
          <DidomiRadioGroup data-testid="user-interactions-buttons-radio-group" disabled={!isCMPEditor} value={preferencesControlType} onValueChange={handleControlTypeChange}>
            <NoticeClosingRadio data-testid="agree-and-disagree-buttons-radio" image="granular-consent" currentValue={preferencesControlType} value="buttons">
              <div className="text-body-small">
                <strong className="font-semibold">{`"Agree" and "Disagree" buttons`}</strong>
              </div>
            </NoticeClosingRadio>
            <NoticeClosingRadio data-testid="toggle-buttons-radio" image="toggle-button" currentValue={preferencesControlType} value="toggles">
              <div className="text-body-small">
                <strong className="font-semibold">Toggle buttons</strong>
              </div>
              <div className="text-tooltip">Users can refuse or accept purposes in a single click.</div>
            </NoticeClosingRadio>
          </DidomiRadioGroup>
        </>
      )}

      <h3 className="text-body-normal font-semibold text-primary-pink-5">Purpose text</h3>

      <DidomiTextInput
        label="Title"
        placeholder="Preferences"
        debounce={INPUT_DEBOUNCE_TIME}
        disabled={loadingNoticeConfig || !isCMPEditor}
        hideErrorMessage
        value={(preferencesContent?.title && preferencesContent.title[trafficLanguage]) || ''}
        onValueChange={e => handlePreferenceTitleChange(e.detail)}
      />

      <DidomiTextarea
        label="Main text"
        disabled={loadingNoticeConfig || !isCMPEditor}
        data-testid="main-text"
        debounce={INPUT_DEBOUNCE_TIME}
        rows={8}
        placeholder={
          RegulationConfigDefaults.getDefaultRegulationConfig(regulationId as Regulation)?.preferences?.content?.text['en'] ||
          `We and our partners place cookies, access and use non-sensitive information from your device to improve our products and personalize ads and other contents throughout this website. You may accept all or part of these operations. To learn more about cookies, partners, and how we use your data, to review your options or these operations for each partner, visit our privacy policy.`
        }
        hideErrorMessage
        value={(preferencesContent?.text && preferencesContent.text[trafficLanguage]) || ''}
        onValueChange={e => handleMainTextChange(e.detail)}
      />

      {/* Purposes */}
      {hasPurposes && <h3 className="text-body-normal font-semibold text-primary-pink-5 mt-xxs">Purposes</h3>}

      {hasPreferencesEnableAllButtons && localConsentNoticeConfig.platform !== 'app' && (
        <NoticeClosingSwitch
          image="granular-consent"
          data-testid="granular-consent"
          value={regulationConfig?.config?.preferences?.enableAllButtons !== undefined ? regulationConfig?.config?.preferences?.enableAllButtons : true}
          disabled={!isCMPEditor}
          onValueChange={handleEnableAllButtonChange}
        >
          <div className="text-body-small">
            <strong className="font-semibold">Enable the “agree to all” and “disagree to all” buttons.</strong>
          </div>
          <div className="text-tooltip">Allow the users to agree or disagree to all in one click.</div>
        </NoticeClosingSwitch>
      )}

      {hasPreferencesEnableBulkActionOnPurposes && (
        // TODO: Remind Jonathan we need a tooltip for when this option is disabled
        <NoticeClosingSwitch
          image="bulk-action"
          data-testid="enable-bulk-buttons"
          value={regulationConfig?.config?.notice?.enableBulkActionOnPurposes}
          disabled={!isCMPEditor || isPurposeViewBulkActionDisabled}
          onValueChange={handleEnableBulkButtonChange}
        >
          <div className="text-body-small">
            <strong className="font-semibold">Enable bulk actions on top of the purpose list.</strong>
          </div>
          <div className="text-tooltip">This will allow users to decline or accept purposes in one single click.</div>
        </NoticeClosingSwitch>
      )}

      {showCombineLiAndConsentToggles && (
        <>
          <NoticeClosingSwitch
            image="c&l-interest-button"
            data-testid="c&l-interest-button"
            value={regulationConfig.preferences_combine_li_and_consent}
            disabled={!isCMPEditor}
            onValueChange={handleConsentAndLIButtonChange}
          >
            <div className="text-body-small">
              <strong className="font-semibold">Consent and Legitimate Interest as a single choice</strong>
            </div>
            <div className="text-tooltip">Users only need to make one selection.</div>
          </NoticeClosingSwitch>

          {regulationConfig.preferences_combine_li_and_consent && (
            <DidomiTextarea
              label="Text for instructions"
              data-testid="combine-li-consent-instructions"
              placeholder={combineLiAndConsentPlaceholder}
              debounce={INPUT_DEBOUNCE_TIME}
              rows={8}
              disabled={loadingNoticeConfig || !isCMPEditor}
              hideErrorMessage
              value={regulationConfig.preferences_content_instructions ? regulationConfig.preferences_content_instructions[trafficLanguage] : ''}
              onValueChange={handleTextForInstructionsChange}
            />
          )}
        </>
      )}

      {/* Buttons */}
      {hasButtons && <h3 className="text-body-normal font-semibold text-primary-pink-5 mt-xxs">Buttons</h3>}

      {hasPreferencesAgree && !isCTVPlatform && (
        <DidomiTextInput
          label="Agree"
          placeholder="Agree"
          debounce={INPUT_DEBOUNCE_TIME}
          description="Consent button for the list of purposes"
          disabled={loadingNoticeConfig || !isCMPEditor}
          hideErrorMessage
          value={(preferencesContent?.agree && preferencesContent.agree[trafficLanguage]) || ''}
          onValueChange={e => handleAgreeChange(e.detail)}
        />
      )}

      {hasPreferencesDisagree && !isCTVPlatform && (
        <DidomiTextInput
          label="Disagree"
          placeholder="Disagree"
          debounce={INPUT_DEBOUNCE_TIME}
          description="Consent button for the list of purposes"
          disabled={loadingNoticeConfig || !isCMPEditor}
          hideErrorMessage
          value={(preferencesContent?.disagree && preferencesContent.disagree[trafficLanguage]) || ''}
          onValueChange={e => handleDisagreeChange(e.detail)}
        />
      )}

      {hasPreferencesViewAllPartners && !isCTVPlatform && (
        <DidomiTextInput
          label="Partners link"
          placeholder="Manage our partners"
          debounce={INPUT_DEBOUNCE_TIME}
          disabled={loadingNoticeConfig || !isCMPEditor}
          hideErrorMessage
          value={(preferencesContent?.viewAllPartners && preferencesContent.viewAllPartners[trafficLanguage]) || ''}
          onValueChange={e => handleViewAllPartnersChange(e.detail)}
        />
      )}

      {(regulationConfig?.config?.preferences?.enableAllButtons !== false || regulationConfig?.config?.notice?.enableBulkActionOnPurposes) && (
        <>
          {hasPreferencesAgreeToAll && (
            <DidomiTextInput
              label="Accept all"
              placeholder="Accept all & Close"
              debounce={INPUT_DEBOUNCE_TIME}
              disabled={loadingNoticeConfig || !isCMPEditor}
              hideErrorMessage
              value={(preferencesContent?.agreeToAll && preferencesContent.agreeToAll[trafficLanguage]) || ''}
              onValueChange={e => handleAgreeToAllChange(e.detail)}
            />
          )}

          {hasPreferencesDisagreeToAll && (
            <DidomiTextInput
              label="Refuse all"
              placeholder="Refuse all & Close"
              debounce={INPUT_DEBOUNCE_TIME}
              disabled={loadingNoticeConfig || !isCMPEditor}
              hideErrorMessage
              value={(preferencesContent?.disagreeToAll && preferencesContent.disagreeToAll[trafficLanguage]) || ''}
              onValueChange={e => handleDisagreeToAllChange(e.detail)}
            />
          )}
        </>
      )}

      {hasPreferencesSave && (
        <DidomiTextInput
          label="Save and close"
          placeholder="Save and close"
          debounce={INPUT_DEBOUNCE_TIME}
          disabled={loadingNoticeConfig || !isCMPEditor}
          hideErrorMessage
          value={(preferencesContent?.save && preferencesContent.save[trafficLanguage]) || ''}
          onValueChange={e => handleSaveChange(e.detail)}
        />
      )}

      {hasPreferencesSubText && !isCTVPlatform && (
        <>
          <h3 className="text-body-normal font-semibold text-primary-pink-5 mt-xxs">Optional text</h3>
          <DidomiTextarea
            label="Text between the partner button and footer"
            debounce={INPUT_DEBOUNCE_TIME}
            disabled={loadingNoticeConfig || !isCMPEditor}
            rows={8}
            placeholder="Type your text here..."
            hideErrorMessage
            value={(preferencesContent?.subText && preferencesContent.subText[trafficLanguage]) || ''}
            onValueChange={e => handleSubTextChange(e.detail)}
          />
        </>
      )}
    </div>
  );
};
