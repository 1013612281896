import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DidomiEmptyState,
  DidomiFiltersBar,
  DidomiPaginator,
  DidomiSkeleton,
  DidomiTable,
  DidomiTableBody,
  DidomiTableHeaderRow,
  DidomiTableHeading,
  DidomiTableRow,
  DidomiTableTd,
  DidomiTableTh,
} from '@didomi/ui-atoms-react';
import { PropertyWithOptions } from '@enums';
import { usePaginatedFilteredItems, useTableState } from '@hooks';
import { AggregatedVendor } from '@interfaces';
import { SortConfig } from '@types';
import { getLastAnalyzedReport } from '@utils';
import { useAggregatedVendors } from '../../hooks/data/aggregated-vendors/useAggregatedVendors.hook';
import { useProperty } from '../../hooks/data/properties/useProperty.hook';

type sortableVendorDomainsFields = 'id';
const SEARCH_FIELDS = [{ field: 'id' }];

interface ComplianceReportUnmatchedVendorDomainsProps {
  selectedVendorHosts?: AggregatedVendor[];
  setSelectedVendorHosts?: Function;
}

const vendorDomainsTableHeader = [{ name: 'Vendor domain', sortId: 'id' }];

const ComplianceReportUnmatchedVendorDomains = ({ selectedVendorHosts, setSelectedVendorHosts }: ComplianceReportUnmatchedVendorDomainsProps) => {
  const { propertyId, reportId: reportIdParam } = useParams();
  const { isLoading: isLoadingProperty, data: property } = useProperty({ id: propertyId, with: [PropertyWithOptions.LATEST_REPORTS] });

  const [reportId, setReportId] = useState<string | undefined>(reportIdParam);
  const [sortConfig] = useState<SortConfig<sortableVendorDomainsFields>>({ field: 'id', dir: 'asc' });

  useEffect(() => {
    if (property?.latest_reports) {
      const lastReport = getLastAnalyzedReport(property);
      if (lastReport) setReportId(lastReport.id);
    }
  }, [property]);

  const { data: vendors, isLoading: isLoadingVendors } = useAggregatedVendors(reportId, {
    unmatchedVendorsOnly: true,
  });

  const isLoading = isLoadingProperty || isLoadingVendors;

  const sortedDomains = useMemo(() => {
    return (vendors || []).sort((a, b) => a.id.localeCompare(b.id));
  }, [vendors]);

  const [selectedVendorDomainsRef, setSelectedVendorDomainsRef] = useState([]);
  const {
    limit,
    page: currPage,
    search,
    changeLimit: setLimit,
    changeSearch: setSearch,
    changePage: setCurrPage,
    changeSorting,
  } = useTableState({
    defaultLimit: 10,
    defaultFilters: {},
  });

  const paginationOptions = useMemo(
    () => ({
      search,
      searchFields: SEARCH_FIELDS,
      translatableSearchFields: [],
      limit: limit,
      page: currPage,
      sortConfig,
    }),
    [search, limit, currPage, sortConfig],
  );

  const {
    data: displayVendorDomains,
    total: totalFilteredVendorsDomainCount,
    totalItems: totalFilteredVendorDomainsItems,
  } = usePaginatedFilteredItems<AggregatedVendor, sortableVendorDomainsFields>(vendors as any, paginationOptions, 'unmatch-vendors');

  useEffect(() => {
    if (selectedVendorHosts) {
      setSelectedVendorDomainsRef(selectedVendorHosts);
    }
  }, [selectedVendorHosts]);
  const handleSelection = useCallback(
    evt => {
      if (evt.type === 'toggleAllRowsSelectedChange') {
        if (evt.detail.selectedItems.length) {
          setSelectedVendorDomainsRef(totalFilteredVendorDomainsItems);
          setSelectedVendorHosts?.(totalFilteredVendorDomainsItems);
        } else {
          setSelectedVendorDomainsRef([]);
          setSelectedVendorHosts?.([]);
        }
      } else {
        setSelectedVendorDomainsRef(evt.detail.newSelectedItems);
        setSelectedVendorHosts?.(evt.detail.newSelectedItems);
      }
    },
    [totalFilteredVendorDomainsItems, setSelectedVendorHosts],
  );
  const handleSearchChange = (e: CustomEvent<string>) => setSearch(e.detail);

  return (
    <div className="mb-xxxl w-full h-auto">
      <DidomiSkeleton isLoading={isLoading} variant="layout">
        <>
          <section className="flex justify-between gap-8 mb-4 w-full" data-skeleton>
            <div className="flex gap-2 text-body-small self-center" style={{ alignSelf: 'center!important' }}>
              <div className="text-primary-blue-6 font-semibold">{`${displayVendorDomains.length} / ${vendors?.length || 0} vendor domains`}</div>
              <div className="text-secondary-cobalt-blue-3">{selectedVendorDomainsRef.length ? selectedVendorDomainsRef.length + ' selected' : ''}</div>
            </div>

            <DidomiFiltersBar
              data-skeleton
              data-testid="filter-bar-vendor-domains"
              id="didomi-filters-without-default-value-vendor-domains"
              defaultValue=""
              filters={{}}
              showSearch={true}
              onSearchTextChange={handleSearchChange}
              placeholderTextFilter={'Search by name'}
              onClearAllFilters={() => setSearch('')}
              collapsibleFilters={false}
            />
          </section>
          <DidomiTable
            selectable
            selectedItems={selectedVendorDomainsRef}
            onRowSelectionChange={handleSelection}
            onToggleAllRowsSelectedChange={handleSelection}
            sortBy={sortConfig.field}
            sortDirection={sortConfig.dir}
            onSortChange={changeSorting}
            sortable={true}
            minWidth={300}
            data-skeleton
            className="w-[calc(100%-2px)] m-auto mt-xs mb-xs"
          >
            <DidomiTableHeading>
              <DidomiTableHeaderRow>
                {vendorDomainsTableHeader.map(obj => (
                  <DidomiTableTh key={obj.name} sortId={obj.sortId} className="text-uppercase">
                    {obj.name}
                  </DidomiTableTh>
                ))}
              </DidomiTableHeaderRow>
            </DidomiTableHeading>
            <DidomiTableBody>
              {sortedDomains.length ? (
                displayVendorDomains.map((vendorDomain, i) => (
                  <DidomiTableRow key={vendorDomain.id} selectionValue={vendorDomain} data-testid={i}>
                    <DidomiTableTd>{vendorDomain.id}</DidomiTableTd>
                  </DidomiTableRow>
                ))
              ) : (
                <DidomiEmptyState illustration-name="traces-no-match-found" className="border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1 mb-s">
                  <div slot="title">
                    There are no more unmatched vendors. <br /> Please run a new report to update the results with your new matched vendors.
                  </div>
                </DidomiEmptyState>
              )}
            </DidomiTableBody>
          </DidomiTable>
          <DidomiPaginator
            data-skeleton
            data-testid="domains-paginator"
            className="flex justify-end mt-s"
            page={currPage}
            itemCount={totalFilteredVendorsDomainCount}
            size={limit}
            onPageSizeChange={setLimit}
            onPageChange={setCurrPage}
          />
        </>
      </DidomiSkeleton>
    </div>
  );
};

export { ComplianceReportUnmatchedVendorDomains };
