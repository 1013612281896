export enum ReportStatus {
  READY = 'ready',
  ANALYZED = 'analyzed',
  FAILURE = 'failure',
  // TODO: new statuses. This is temporary. Once we migrate all reports, we will remove the above enums.
  PENDING = 'pending',
  QUEUED = 'queued',
  FAILED = 'failed',
  SUCCESS = 'success',
  PARTIAL = 'partial',
}
