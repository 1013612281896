import { ScenarioType } from '@enums';

/**
 * Computes the functional scenario based on the provided scenario IDs.
 *
 * Priority order:
 * 1. `ScenarioType.NO_ACTIONS` has the highest priority and will be returned if present.
 * 2. `ScenarioType.REFUSE_ALL` is returned if present (unless `NO_ACTIONS` is also present).
 * 3. If the input is empty or undefined, `ScenarioType.NO_USER_CHOICE` is returned.
 *
 * @param {ScenarioType[]} scenarioIds - An array of scenario IDs to compute the functional scenario.
 * @returns {ScenarioType} The computed functional scenario.
 */
export function computeFunctionalScenario(scenarioIds: ScenarioType[]): ScenarioType {
  if (!scenarioIds || !scenarioIds.length) {
    return ScenarioType.NO_USER_CHOICE;
  }

  // NO_USER_CHOICE has priority over all
  if (scenarioIds.includes(ScenarioType.NO_USER_CHOICE)) {
    return ScenarioType.NO_USER_CHOICE;
  }

  if (scenarioIds.includes(ScenarioType.REFUSE_TO_ALL)) {
    return ScenarioType.REFUSE_TO_ALL;
  }

  return ScenarioType.CONSENT_TO_ALL;
}
