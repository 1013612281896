import { TRANSLATED_SCENARIOS_MAP } from '@constants';
import { AggregatedTracker, AggregatedVendor } from '@types';

/**
 * Filters and concatenates succeeded scenario keys from a tracker, a request or a vendor into a string.
 * @returns {string} A comma-separated string of translated scenario keys.
 * @param entity
 */
export const getRanScenarios = (entity: Partial<AggregatedTracker> | Partial<AggregatedVendor>): string => {
  const succeededScenarios = entity.cmp?.scenario?.id || [];

  if (!succeededScenarios.length) {
    return '-';
  }

  const scenarios = succeededScenarios.map(scenario => TRANSLATED_SCENARIOS_MAP[scenario] || scenario).sort((a, b) => a.localeCompare(b));

  return scenarios.join(', ');
};
