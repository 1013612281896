import React, { useState, useMemo, useCallback, useEffect } from 'react';
import {
  DidomiFiltersBar,
  DidomiPaginator,
  DidomiSkeleton,
  DidomiTable,
  DidomiTableBody,
  DidomiTableHeaderRow,
  DidomiTableHeading,
  DidomiTableRow,
  DidomiTableTh,
  DidomiTableTd,
  DidomiEmptyState,
  DidomiHintbox,
} from '@didomi/ui-atoms-react';
import { useActiveOrganization } from '@didomi/utility-react';

import { useSessionStorage } from 'usehooks-ts';
import { VendorNamespace } from '@enums';
import { useAggregatedVendors } from '@hooks';
import { AggregatedVendor } from '@interfaces';
import { DraftVendor } from '@types';

interface UnmatchedVendorDomainsProps {
  selectedVendorHosts?: any[];
  setSelectedVendorHosts?: Function;
  matchingVendorHost: string | null;
}
const vendorDomainsTableHeader = [{ name: 'Vendor domain', sortId: 'id' }];

const typeOfVendor = (vendor: AggregatedVendor) => {
  return vendor.functional_namespace === VendorNamespace.BUILT_IN ? 'Model' : '';
};

const UnmatchedVendorDomains = ({ selectedVendorHosts, setSelectedVendorHosts }: UnmatchedVendorDomainsProps) => {
  const { organizationId } = useActiveOrganization();

  const [storedDraftVendorFormData] = useSessionStorage<DraftVendor>('draft-vendor-' + organizationId, null);

  const {
    data: vendorsResponse,
    isLoading: isLoadingVendors,
    paginator: { page: currPage, limit, search, setPage: setCurrPage, setLimit, setSearch },
  } = useAggregatedVendors({
    unmatchedDomainsOnly: true,
  });

  const totalFilteredVendorsDomainCount = vendorsResponse?.total || 0;

  const [selectedVendorDomainsRef, setSelectedVendorDomainsRef] = useState([]);

  useEffect(() => {
    if (selectedVendorHosts) setSelectedVendorDomainsRef(selectedVendorHosts);
  }, [selectedVendorHosts]);

  useEffect(() => {
    if (storedDraftVendorFormData?.vendor?.hosts?.length) {
      const selectedHosts = vendorsResponse?.data.filter(elm => storedDraftVendorFormData.vendor.hosts.includes(elm.id));
      setSelectedVendorDomainsRef(selectedHosts);
    }
  }, [vendorsResponse?.data, storedDraftVendorFormData]);

  const handleSelection = useCallback(
    evt => {
      /* istanbul ignore if - Header checbox does not appear during jest test */
      if (evt.type === 'toggleAllRowsSelectedChange') {
        if (evt.detail.selectedItems.length) {
          setSelectedVendorDomainsRef(vendorsResponse?.data);
          setSelectedVendorHosts(vendorsResponse?.data);
        } else {
          setSelectedVendorDomainsRef([]);
          setSelectedVendorHosts([]);
        }
      } else {
        setSelectedVendorDomainsRef(evt.detail.newSelectedItems);
        setSelectedVendorHosts(evt.detail.newSelectedItems);
      }
    },
    [vendorsResponse?.data, setSelectedVendorHosts],
  );

  return (
    <div className="pb-unsafe-bottom-space w-full">
      <DidomiHintbox variant="warning" iconName="warning" className="my-xxs">
        The vendors list will contain data with a 24 hour delay.
      </DidomiHintbox>
      <DidomiSkeleton isLoading={isLoadingVendors} variant="layout">
        <>
          <section className="flex justify-between gap-8 mb-xs w-full" data-skeleton>
            <div className="flex gap-2 text-body-small self-center" style={{ alignSelf: 'center!important' }}>
              <div className="text-primary-blue-6 font-semibold">{`${vendorsResponse?.data.length || 0} / ${totalFilteredVendorsDomainCount} vendor domains`}</div>
              <div className="text-secondary-cobalt-blue-3">{selectedVendorDomainsRef?.length ? selectedVendorDomainsRef.length + ' selected' : ''}</div>
            </div>

            <DidomiFiltersBar
              data-testid="filter-bar-vendor-domains"
              id="didomi-filters-without-default-value-vendor-domains"
              defaultValue={search}
              searchValue={search}
              filters={{}}
              showSearch={true}
              onSearchTextChange={setSearch}
              placeholderTextFilter={'Search by name'}
              onClearAllFilters={setSearch}
              collapsibleFilters={false}
            ></DidomiFiltersBar>
          </section>
          <DidomiTable
            selectable
            selectedItems={selectedVendorDomainsRef}
            onRowSelectionChange={handleSelection}
            onToggleAllRowsSelectedChange={handleSelection}
            sortable={false}
            minWidth={300}
            data-skeleton
            className="w-[calc(100%-2px)] mx-auto mb-s"
          >
            <DidomiTableHeading>
              <DidomiTableHeaderRow>
                {vendorDomainsTableHeader.map(obj => (
                  <DidomiTableTh key={obj.name} sortId={obj.sortId} className="text-uppercase">
                    {obj.name}
                  </DidomiTableTh>
                ))}
              </DidomiTableHeaderRow>
            </DidomiTableHeading>
            <DidomiTableBody>
              {vendorsResponse?.data?.length ? (
                vendorsResponse?.data?.map((vendorDomain, i) => (
                  <DidomiTableRow key={vendorDomain.id} selectionValue={vendorDomain} data-testid={i}>
                    <DidomiTableTd>{vendorDomain.id}</DidomiTableTd>
                    <DidomiTableTd className="text-primary-pink-5">{typeOfVendor(vendorDomain)}</DidomiTableTd>
                  </DidomiTableRow>
                ))
              ) : (
                <DidomiEmptyState illustration-name="traces-no-match-found" className="border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1 mb-s">
                  <div slot="title">Unfortunately we don’t have any domains to suggest.</div>
                </DidomiEmptyState>
              )}
            </DidomiTableBody>
          </DidomiTable>

          <div className="flex justify-end">
            <DidomiPaginator
              data-skeleton
              data-testid="domains-paginator"
              page={currPage}
              itemCount={totalFilteredVendorsDomainCount}
              size={limit}
              onPageSizeChange={setLimit}
              onPageChange={setCurrPage}
            />
          </div>
        </>
      </DidomiSkeleton>
    </div>
  );
};

export { UnmatchedVendorDomains };
