import React, { useEffect } from 'react';
import { ComplianceReportCard, ComplianceReportEmptyData } from '@components';
import { DidomiSkeleton, DidomiPaginator, DidomiFiltersBar, DidomiHintbox } from '@didomi/ui-atoms-react';
import { PropertyWithOptions, ReportStatus } from '@enums';
import { useAccessPolicies, useReportStatusCheck } from '@hooks';
import { SortConfig } from '@types';
import { orderReportsByDate } from '@utils';
import { useProperties } from '../../hooks/data/properties/useProperties.hook';
import { useVerifyScrapingQuota } from '../../hooks/useVerifyScrapingQuota.hook';
import { Property } from '../../types/responses/property';

const FINAL_REPORT_STATES = [ReportStatus.SUCCESS, ReportStatus.PARTIAL, ReportStatus.FAILED];

const SORT: SortConfig<keyof Property> = {
  field: 'created_at',
  dir: 'desc',
};

interface ComplianceReportsProps {
  setIsQuotaExceeded?: (value: boolean) => void;
}

const ComplianceReports = ({ setIsQuotaExceeded }: ComplianceReportsProps) => {
  const { hasComplianceReportEditAccess, isLoading: isLoadingAccessPolicies } = useAccessPolicies();
  const {
    data: { data: listProperties, total: totalProperties } = {},
    isLoading: isLoadingProperties,
    paginator: { page, limit, search, setLimit: handleLimitChange, setPage: handlePageChange, setSearch: handleSearchChange },
    error,
    refetch,
  } = useProperties({
    with: [PropertyWithOptions.LATEST_REPORTS],
    sort: SORT,
  });

  const { organizationQuota, isQuotaExceeded } = useVerifyScrapingQuota({ totalProperties });
  useEffect(() => {
    if (setIsQuotaExceeded) {
      setIsQuotaExceeded(isQuotaExceeded);
    }
  }, [organizationQuota, isQuotaExceeded, setIsQuotaExceeded]);

  const { includeReportsToCheck } = useReportStatusCheck({
    withStatus: FINAL_REPORT_STATES,
    onReportsChange: () => {
      refetch();
    },
  });

  useEffect(() => {
    if (listProperties?.length) {
      const reports = listProperties
        .map(property => {
          const orderedReports = orderReportsByDate(property.latest_reports || []);
          return orderedReports[0];
        })
        .filter(Boolean);

      includeReportsToCheck(reports);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listProperties]);

  const isLoading = isLoadingProperties || isLoadingAccessPolicies;

  const handleFiltersReset = () => {
    handleSearchChange({
      detail: '',
    });
  };

  return (
    <div className="pb-unsafe-bottom-space">
      {isQuotaExceeded && (
        <DidomiHintbox titleText="Important" iconName="warning" variant="warning" className="mt-m">
          You have reached the <strong>maximum</strong> number of active domain allowed ({organizationQuota}). To add domains, you need to remove some first.
        </DidomiHintbox>
      )}
      <div className="mt-xs mb-xs">
        <DidomiFiltersBar
          data-skeleton
          data-testid="filter-bar-score"
          defaultValue="website"
          id="didomi-filters-without-default-value"
          placeholderTextFilter={'Search'}
          left-text={totalProperties === listProperties?.length ? `${listProperties?.length || 0} domains` : `${listProperties?.length || 0} / ${totalProperties || 0} domains`}
          searchValue={search}
          showSearch={true}
          collapsible-filters={true}
          onSearchTextChange={handleSearchChange}
          onClearAllFilters={handleFiltersReset}
        ></DidomiFiltersBar>
      </div>
      <DidomiSkeleton isLoading={isLoading} className="h-[80vh] w-full">
        {!isLoading &&
          (listProperties?.length ? (
            <div>
              <div>
                {listProperties.map((property, key) => {
                  return (
                    <div key={`website-${property.id}`} data-skeleton>
                      <ComplianceReportCard
                        hasComplianceReportEditAccess={hasComplianceReportEditAccess}
                        menuKey={key}
                        property={property}
                        onPropertyDeleted={refetch}
                        onReportCreated={refetch}
                      />
                    </div>
                  );
                })}
              </div>
              <DidomiPaginator
                data-skeleton
                className="self-end mb-xs w-full justify-end flex mb-[150px]"
                variant="normal"
                page={page}
                itemCount={totalProperties}
                size={limit}
                onPageSizeChange={handleLimitChange}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <ComplianceReportEmptyData error={!!error} />
          ))}
      </DidomiSkeleton>
    </div>
  );
};

export { ComplianceReports };
