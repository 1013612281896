import React from 'react';
import { useReleaseFlag } from '@didomi/utility-react';
import { UnmatchedVendorDomains, UnmatchedVendorDomainsOld } from '@components';

interface MatchVendorDomainsProps {
  selectedVendorHosts?: any[];
  setSelectedVendorHosts?: Function;
  matchingVendorHost?: string;
}
/**
 * MatchVendorDomains Page
 */
export const MatchVendorDomains = ({ selectedVendorHosts, matchingVendorHost, setSelectedVendorHosts }: MatchVendorDomainsProps): JSX.Element => {
  const [isReportsV2Enabled, isLoadingReportsV2Enabled] = useReleaseFlag('reports_v2');
  return (
    <main className="w-full">
      {isReportsV2Enabled && !isLoadingReportsV2Enabled ? (
        <UnmatchedVendorDomains selectedVendorHosts={selectedVendorHosts} matchingVendorHost={matchingVendorHost} setSelectedVendorHosts={setSelectedVendorHosts} />
      ) : (
        <UnmatchedVendorDomainsOld selectedVendorHosts={selectedVendorHosts} matchingVendorHost={matchingVendorHost} setSelectedVendorHosts={setSelectedVendorHosts} />
      )}
    </main>
  );
};
