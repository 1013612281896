import { useCallback, useMemo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { PageLimitType } from '@types';

const PAGE_KEY = 'page';
const LIMIT_KEY = 'limit';
const SEARCH_QUERY_KEY = 'search';
const DEFAULT_VALUE_PAGE = 1;
const DEFAULT_VALUE_LIMIT = 10 as PageLimitType;
const SEARCH_DEBOUNCE_TIME = 100;

interface PaginationOptions {
  defaultLimit?: PageLimitType;
  pushHistory?: boolean;
  debounceSearch?: boolean;
  searchQueryKey?: string;
  maxItems?: number;
  debounceTime?: number;
}

export const usePaginationQueryParams = (options?: PaginationOptions) => {
  const defaultLimit = options?.defaultLimit ?? DEFAULT_VALUE_LIMIT;
  const searchQueryKey = options?.searchQueryKey ?? SEARCH_QUERY_KEY;
  const maxItems = options?.maxItems;
  const debounceTime = options?.debounceTime ?? SEARCH_DEBOUNCE_TIME;

  let [searchParams, setSearchParams] = useSearchParams();

  // Reduce last page if size is bigger than the amount of items
  useEffect(() => {
    if (!maxItems) return;

    const pageNum = Number.parseInt(searchParams.get(PAGE_KEY));
    const pageLimit = Number.parseInt(searchParams.get(LIMIT_KEY));

    if (maxItems < pageNum * pageLimit) {
      const lastPage = Math.ceil(maxItems / pageLimit);
      searchParams.set('page', lastPage.toString());
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams, maxItems]);

  const page = useMemo(() => {
    const pageNum = Number.parseInt(searchParams.get(PAGE_KEY));
    if (Number.isNaN(pageNum) || !pageNum || pageNum < 0) {
      return DEFAULT_VALUE_PAGE;
    }
    return pageNum;
  }, [searchParams]);

  const limit = useMemo(() => {
    const pageLimit = Number.parseInt(searchParams.get(LIMIT_KEY));
    if (Number.isNaN(pageLimit) || !pageLimit || pageLimit < 0) {
      return defaultLimit;
    }
    return pageLimit as PageLimitType;
  }, [searchParams, defaultLimit]);

  const search = useMemo(() => {
    return searchParams.get(searchQueryKey) || '';
  }, [searchParams, searchQueryKey]);
  const skip = useMemo(() => limit * (page - 1), [limit, page]);

  const setPage = useCallback(
    (ev: CustomEvent<{ page: number }>) => {
      if (ev.detail?.page) {
        if (ev.detail.page === DEFAULT_VALUE_PAGE) {
          searchParams.delete('page');
        } else {
          searchParams.set('page', ev.detail.page.toString());
        }
      } else {
        searchParams.delete('page');
      }
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams],
  );

  const setLimit = useCallback(
    (ev: CustomEvent<{ limit: number }>) => {
      if (ev.detail?.limit) {
        if (ev.detail.limit === defaultLimit) {
          searchParams.delete('limit');
        } else {
          searchParams.set('limit', ev.detail.limit.toString());
        }
      } else {
        searchParams.delete('limit');
      }
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams, defaultLimit],
  );

  const setSearch = useCallback(
    (ev: CustomEvent<string>) => {
      if (ev.detail) {
        searchParams.set('search', ev.detail);
        searchParams.delete('page'); // Every new search resets to the first page
      } else {
        searchParams.delete('search');
      }
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams],
  );

  const setDebouncedSearch = useMemo(() => {
    return debounce(setSearch, debounceTime);
  }, [setSearch, debounceTime]);

  return {
    page,
    limit,
    skip,
    setPage,
    setLimit,
    search,
    setSearch: options?.debounceSearch ? setDebouncedSearch : setSearch,
  };
};
