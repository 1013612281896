import { SCENARIO_KEYS } from '@constants';
import { ScenarioType } from '@enums';

/**
 * Adapts a given scenario type to its corresponding scenario key.
 *
 * @param {string} scenario - The scenario type to adapt.
 * Expected values are:
 * - `"no_user_choice"`
 * - `"consent_to_all"`
 * - `"refuse_to_all"`
 *
 * @returns {string | undefined} - The corresponding scenario key from `SCENARIO_KEYS`.
 *
 * @example
 * const key = functionalScenarioAdapter("CONSENT_TO_ALL");
 * // key = SCENARIO_KEYS.CONSENT_TO_ALL
 */
export const functionalScenarioAdapter = (scenario: string) => {
  return {
    [ScenarioType.NO_USER_CHOICE]: SCENARIO_KEYS.NO_USER_CHOICE,
    [ScenarioType.CONSENT_TO_ALL]: SCENARIO_KEYS.CONSENT_TO_ALL,
    [ScenarioType.REFUSE_TO_ALL]: SCENARIO_KEYS.REFUSE_TO_ALL,
  }[scenario];
};
