import { useState } from 'react';
import { getTranslatedValue } from '@didomi/helpers';
import { get } from 'lodash';
import { REPORT_PDF_CONFIG, TRANSLATED_SCENARIOS_MAP } from '@constants';
import { ReportOutputKey } from '@enums';
import { AggregatedVendor, AggregatedTracker, ExportType, Property, Report, ReportInfo } from '@types';
import { exportCSV, exportExcel, exportJSON, exportPDF, formatDateMMDDYY, getTrackerAgeInMonthOrDays, getRanScenarios, computeFunctionalScenario } from '@utils';
import { usePropertyCookiePolicies } from './data/cookie-policies/usePropertyCookiePolicies.hook';
import { usePropertyReport } from './usePropertyReport.hook';
import { useTrackerExemptionCategories } from './useTrackerExemptionCategories.hook';

type UseReportExportArgs = {
  reportId?: string;
  property?: Partial<Property>;
};

type ExportReportArgs = {
  info: ReportInfo;
  type: ExportType;
};

type FUNCTION_EXTRACTOR<T, S> = (item: T, base?: S) => string | string[];

type EXTRACTOR<T, S> = string | FUNCTION_EXTRACTOR<T, S>;

const TRACKER_EXTRACTOR: Record<string, EXTRACTOR<AggregatedTracker, Report>> = {
  'Initiator Vendor': (tracker: AggregatedTracker) => tracker.initiator?.vendor?.name || tracker.initiator?.url_host,
  'Initiator URL': (tracker: AggregatedTracker) => tracker.initiator?.url,
  Vendor: (tracker: AggregatedTracker) => tracker.vendor?.name || tracker.host || tracker.sld,
  'Page url': 'page_url',
  'Cookie domain': 'host',
  'Cookie name': 'name',
  'Lifetime (in seconds)': 'max_lifetime_seconds',
  sample: (tracker: AggregatedTracker) => (tracker.value ? tracker.value.replace(/,/g, '_').replace(/"/g, '') : ''),
  Party: (tracker: AggregatedTracker) => (tracker.is_third_party ? '3rd party' : '1st party'),
  'User behaviour': (tracker: AggregatedTracker) => TRANSLATED_SCENARIOS_MAP[computeFunctionalScenario(tracker.cmp?.scenario?.id)],
  'Ran Scenarios': getRanScenarios,
  Type: 'type',
  Purposes: (tracker: AggregatedTracker) => (tracker.purposes.length > 0 ? tracker.purposes.map(purpose => getTranslatedValue(purpose.description)).join(', ') : 'None'),
  Exemption: (tracker: AggregatedTracker) => (tracker.is_exempt ? 'Yes' : 'No'),
};

const COOKIE_POLICY_EXTRACTOR: Record<string, EXTRACTOR<AggregatedTracker, Report>> = {
  'Vendor Name': (tracker: AggregatedTracker) => tracker.vendor?.name || tracker.host || tracker.sld,
  'Vendor tcf id': 'vendor_iabv2_id',
  'Tracker Name': 'name',
  'Tracker domain': 'host',
  'Tracker exemption': (tracker: AggregatedTracker) => (tracker.is_exempt ? 'Exempted' : 'Not exempted'),
  'Tracker exemption title': (tracker: AggregatedTracker) => getTranslatedValue(tracker.exemption?.title) || '',
  'Tracker exemption description': (tracker: AggregatedTracker) => getTranslatedValue(tracker.exemption?.description) || '',
  'Life Time': (tracker: AggregatedTracker) => getTrackerAgeInMonthOrDays(tracker.max_lifetime_seconds),
  '1st Party/ 3rd Party': (tracker: AggregatedTracker) => (tracker.is_third_party ? '3rd party' : '1st party'),
  'Tracker Type': 'type',
  'Tracker Purposes Ids': (tracker: AggregatedTracker) => tracker.purposes?.map(purpose => purpose.id) || [],
  'Tracker Purposes Names': (tracker: AggregatedTracker) => tracker.purposes?.map(purpose => getTranslatedValue(purpose.description)) || [],
};

const VENDOR_EXTRACTOR: Record<string, EXTRACTOR<AggregatedVendor, Report>> = {
  Date: (vendor: AggregatedVendor) => formatDateMMDDYY(vendor.created_at),
  Vendor: (vendor: AggregatedVendor) => vendor.partner?.name || vendor.id,
  'Page url': 'page_url',
  'Request url': 'url',
  // 'Is tag': (vendor: AggregatedVendor) => (vendor.type.includes('javascript') ? 'Yes' : 'No'),
  'Tag type': 'type',
  'TCF id': 'vendor_iabv2_id',
  'Initiator url': 'initiator_url',
  // 'Initiator vendor': (request: AggregatedVendor) => request.initiator_vendor_name || request.initiator_url_sld,
  // 'Legal Basis': (request: AggregatedVendor) => legalBasisFrom(request.vendor_tcfv2_data),
  // 'Initiator Legal Basis': (request: AggregatedVendor) => legalBasisFrom(request.initiator_vendor_tcfv2_data),
  // 'User behaviour': (request: AggregatedVendor) => TRANSLATED_SCENARIOS_MAP[request.functional_scenario],
  'Ran Scenarios': getRanScenarios,
};

/**
 * Groups rows by the 'Vendor Name' column and exports the result as a JSON file.
 *
 * @param {Record<string, string | string>[]} rows - The rows of extracted data.
 * @param {string} fileName - The name of the file to generate.
 */
const groupCookiePolicy = (rows: Record<string, string>[]) => {
  const groupByColumn = 'Vendor Name';
  const cookiePolicyContent = {};

  rows.forEach(item => {
    const vendorName = item[groupByColumn] as string;
    if (!cookiePolicyContent[vendorName]) {
      cookiePolicyContent[vendorName] = {
        [`${groupByColumn}`]: vendorName,
        Trackers: [],
      };
    }
    cookiePolicyContent[vendorName].Trackers.push(item);
  });

  return Object.values(cookiePolicyContent);
};

const EXTRACTORS: Record<ReportInfo, Record<string, EXTRACTOR<AggregatedTracker | AggregatedVendor, Report>>> = {
  [ReportInfo.TRACKER]: TRACKER_EXTRACTOR,
  [ReportInfo.VENDOR]: VENDOR_EXTRACTOR,
  [ReportInfo.COOKIE_POLICY]: COOKIE_POLICY_EXTRACTOR,
};

const FILE_NAMES = {
  [ReportInfo.TRACKER]: 'tracker',
  [ReportInfo.VENDOR]: 'vendor',
  [ReportInfo.COOKIE_POLICY]: 'qualified-cookie-policy',
};

/**
 * Maps and reduces items (Vendors or Trackers) into a structured format for report generation, using provided extractors.
 * @param {Report} report - The report object containing detailed information.
 * @param {AggregatedVendorNew[] | AggregatedTracker[]} items - An array of Vendors or Trackers objects to process.
 * @param {typeof TRACKER_EXTRACTOR | typeof VENDOR_EXTRACTOR} extractor - The specific extractor to use for mapping the items.
 * @returns {Record<string, string>[]} An array of records, each representing a row of data for the report.
 */
const getRows = (report: Report, items: AggregatedVendor[] | AggregatedTracker[], extractor: typeof TRACKER_EXTRACTOR | typeof VENDOR_EXTRACTOR) => {
  return items.map(item => {
    return Object.keys(extractor).reduce((acc, extractorKey) => {
      const extractorOperator = extractor[extractorKey];
      if (typeof extractorOperator === 'string') {
        acc[extractorKey] = get(item, extractorOperator);
      } else {
        acc[extractorKey] = extractorOperator(item, report);
      }
      return acc;
    }, {});
  });
};

/**
 * Generates a file (PDF, Excel, or CSV) based on the provided rows and type.
 * @param {Record<string, string>[]} rows - The rows of data to include in the file.
 * @param {ExportType} type - The type of file to generate (PDF, Excel, or CSV).
 * @param {string} fileName - The name of the file to generate.
 * @returns {Promise<void>} A promise that resolves when the file is successfully generated.
 */
const generateFile = async (rows: Record<string, any>[], type: ExportType, fileName: string) => {
  if (type === ExportType.PDF) {
    return exportPDF(rows, fileName, { orientation: 'landscape', format: 'a3' }, REPORT_PDF_CONFIG);
  }
  if (type === ExportType.EXCEL) {
    return await exportExcel(rows, fileName);
  }

  if (type === ExportType.JSON) {
    return exportJSON(rows, fileName);
  }

  return exportCSV(rows, fileName);
};

/**
 * Custom React hook for managing the export of reports.
 * @param {UseReportExportArgs} params - The arguments object, containing an optional report.
 * @returns {object} An object containing the current loading status, error (if any), report info, report type, and the exportReport function.
 */
export const useReportExport = ({ reportId, property }: UseReportExportArgs = {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportInfo, setReportInfo] = useState<ReportInfo>(null);
  const [reportType, setReportType] = useState<ExportType>(null);
  const [error, setError] = useState<Error>(null);

  const { fetch: fetchReport } = usePropertyReport({ reportId, enabled: false, isLegacy: false });
  const { fetch: fetchCookiePolicy } = usePropertyCookiePolicies({ enabled: false });
  const { fetch: fetchTrackerExemptionCategories } = useTrackerExemptionCategories({ enabled: false });

  const getExportData = async (report: Report, info: ReportInfo) => {
    const extractors = EXTRACTORS[info];

    switch (info) {
      case ReportInfo.VENDOR: {
        // load vendors from S3
        // TODO: refactor to use useReportOutput hook
        const vendorsResponse = await fetch(report.outputs[ReportOutputKey.AGGREGATED_VENDORS]);
        const vendors = await vendorsResponse.json();
        // map vendors to rows
        const rows = getRows(report, vendors, extractors);

        return rows;
      }
      case ReportInfo.TRACKER: {
        // load cookie policy from S3
        const cookiePolicy = await fetchCookiePolicy(property?.id);
        // map cookie policy to rows
        const rows = getRows(report, cookiePolicy.data as any, extractors);

        return rows;
      }
      case ReportInfo.COOKIE_POLICY: {
        const [cookiePolicy, trackerExemptionCategories] = await Promise.all([fetchCookiePolicy(property?.id), fetchTrackerExemptionCategories()]);

        // add exemption to cookie policy
        const updatedCookiePolicy = cookiePolicy.data.map(cookie => {
          const exemption = trackerExemptionCategories.data.find(category => category.id === cookie.exemption_category_id);
          return {
            ...cookie,
            exemption,
          };
        });

        // map cookie policy to rows
        const rows = getRows(report, updatedCookiePolicy as any, extractors);

        // group cookie policy by vendor
        const groupedCookiePolicy = groupCookiePolicy(rows);

        return groupedCookiePolicy;
      }
    }
  };

  /**
   * Export the report
   * @param {ExportReportArgs} params - The arguments object, containing an optional report.
   */
  const exportReport = async ({ info, type }: ExportReportArgs) => {
    setIsLoading(true);
    setError(null);
    setReportInfo(info);
    setReportType(type);

    try {
      const report = await fetchReport(reportId);
      const data = await getExportData(report, info);
      const fileName = `export-${FILE_NAMES[info]}-${property.name.replace(/ /g, '-')}-${formatDateMMDDYY(report.created)}`.replace('_', '-');

      await generateFile(data, type, fileName);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  return {
    error,
    isLoading,
    reportInfo,
    reportType,
    exportReport,
  };
};
