import { useMemo } from 'react';
import { ReportOutputKey } from '@enums';
import { AggregatedVendor } from '@interfaces';
import { useReportOutput } from '../report-outputs/useReportOutput.hook';

interface UseAggregatedVendorsOptions {
  unmatchedVendorsOnly?: boolean;
}

export const useAggregatedVendors = (reportId: string, options: UseAggregatedVendorsOptions = {}) => {
  const { data: vendors, isLoading } = useReportOutput<AggregatedVendor>(reportId, ReportOutputKey.AGGREGATED_VENDORS, {});

  const filteredVendors = useMemo(() => (options.unmatchedVendorsOnly ? vendors?.filter(vendor => !vendor.partner) : vendors), [vendors, options.unmatchedVendorsOnly]);

  return {
    data: filteredVendors || [],
    isLoading,
  };
};
