export enum ScenarioType {
  // TODO: Remove legacy scenario types.
  ACCEPT_ALL = 'accept_all',
  REFUSE_ALL = 'refuse_all',
  NO_ACTIONS = 'no_actions',
  CUSTOM = 'custom',
  // new types (AWS)
  ACCEPT_TO_ALL = 'accept_to_all',
  CONSENT_TO_ALL = 'consent_to_all',
  REFUSE_TO_ALL = 'refuse_to_all',
  NO_USER_CHOICE = 'no_user_choice',
}
