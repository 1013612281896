import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import React from 'react';
import { DidomiSecondaryHeader, DidomiBackButtonLink, DidomiHintbox, DidomiSkeleton, DidomiButton } from '@didomi/ui-atoms-react';
import { useSPARouter } from '@didomi/utility-react';
import { useQueryClient } from 'react-query';
import { useSessionStorage } from 'usehooks-ts';
import { ComplianceReportMatchDomainsToVendor } from '@components';

/**
 * Main Layout
 * Default layout with header
 */
export const MatchDomainsToVendorPage = () => {
  const navigate = useNavigate();
  const { navigateTo } = useSPARouter();
  const { reportId, propertyId } = useParams();
  const [searchParams] = useSearchParams();
  const [storedUnknownVendorDomains] = useSessionStorage('unknown-vendors-domains-' + reportId, null);
  const queryClient = useQueryClient();

  const handleBackButton = () => {
    const params = new URLSearchParams(searchParams);
    const legacyPath = searchParams.get('report_provider') === 'legacy' ? 'legacy/' : '';
    const queryString = params.toString() ? `?${params.toString()}` : '';
    navigate(`/domain/${propertyId}/match-unknown-domains/${legacyPath}${reportId}${queryString}`);
  };

  const handleCreateNewVendor = async () => {
    await queryClient.resetQueries();
    navigateTo(
      // TODO only use .id after the migration
      `/data-manager/add-vendor?reportDomain=${reportId}[${propertyId}]&vendorKey=${
        storedUnknownVendorDomains?.[0]?.url_sld || storedUnknownVendorDomains?.[0]?.id || 'domain.com'
      }&fromPath=${window.location.pathname}`,
    );
  };

  return (
    <section className="p-12 pb-6 h-full box-border !flex flex-col h-auto">
      <DidomiSkeleton variant="layout" isLoading={false}>
        <div>
          <DidomiSecondaryHeader
            title-text={
              !storedUnknownVendorDomains || !storedUnknownVendorDomains?.length
                ? `You must select unknown domain, please go back`
                : `Matching: ${storedUnknownVendorDomains.length} unknown vendor domains`
            }
            actions-in-center
          >
            <div slot="back-button">
              <DidomiBackButtonLink data-testid="back-btn" className="cursor-pointer" onClick={handleBackButton}></DidomiBackButtonLink>
            </div>
            <div slot="actions">
              <DidomiButton variant="secondary" onClick={handleCreateNewVendor}>
                Create new vendor
              </DidomiButton>
            </div>
          </DidomiSecondaryHeader>
          <DidomiHintbox title-text="" className="mt-s">
            {!storedUnknownVendorDomains || !storedUnknownVendorDomains?.length ? (
              <span className="font-semibold text-primary-blue-6" style={{ fontSize: '16px' }}>
                You did not select any unknown vendor domain, please go back and select those you want to match
              </span>
            ) : (
              // Not super elegant way to force the good whitespace between elements
              <span className="font-semibold text-primary-blue-6" style={{ fontSize: '16px' }}>
                {'Match '}
                {storedUnknownVendorDomains.map((domain, index) => {
                  return (
                    <span key={index}>
                      {/* TODO only use .id after the migration */}
                      <span className="font-semibold italic text-primary-blue-4">{domain.url_sld || domain.id}</span>
                      {index < storedUnknownVendorDomains.length - 1 && <span className="text-black">, </span>}
                    </span>
                  );
                })}
                {' to one of the vendors listed below or create a new vendor.'}
              </span>
            )}
          </DidomiHintbox>
        </div>
      </DidomiSkeleton>
      <ComplianceReportMatchDomainsToVendor></ComplianceReportMatchDomainsToVendor>
    </section>
  );
};
