import { ScenarioType } from '@enums';
import { Scenario } from '@types';

export const SCENARIO_KEYS = {
  CONSENT_TO_ALL: 'CONSENT_TO_ALL',
  REFUSE_TO_ALL: 'REFUSE_TO_ALL',
  NO_USER_CHOICE: 'NO_USER_CHOICE',
};

export const SCENARIOS = [
  { key: SCENARIO_KEYS.CONSENT_TO_ALL, text: 'Consent to all' },
  { key: SCENARIO_KEYS.REFUSE_TO_ALL, text: 'Refuse to all' },
  { key: SCENARIO_KEYS.NO_USER_CHOICE, text: 'No CMP choice' },
];

export const TRANSLATED_SCENARIOS_MAP_OLD: Record<Scenario['key'], string> = {
  CONSENT_TO_ALL: 'Consent to all',
  REFUSE_TO_ALL: 'Refuse to all',
  NO_USER_CHOICE: 'No CMP choice',
};

export const TRANSLATED_SCENARIOS_MAP: Record<ScenarioType, string> = {
  [ScenarioType.CONSENT_TO_ALL]: 'Consent to all',
  [ScenarioType.ACCEPT_TO_ALL]: 'Accept to all',
  [ScenarioType.ACCEPT_ALL]: 'Consent to all',
  [ScenarioType.REFUSE_TO_ALL]: 'Refuse to all',
  [ScenarioType.REFUSE_ALL]: 'Refuse to all',
  [ScenarioType.NO_USER_CHOICE]: 'No CMP choice',
  [ScenarioType.NO_ACTIONS]: 'No CMP choice',
  [ScenarioType.CUSTOM]: 'Custom',
};

export const NO_SCHEDULE_SET = '-';
export const SCHEDULE_LIST_MAP = {
  Once: NO_SCHEDULE_SET, // once (single shot)
  Daily: '0 0 * * *', // run each day at midnight (00:00)
  Weekly: '0 0 * * 0', // run each friday at midnight (00:00)
  Monthly: '0 0 1 * *', // run each 1st of month at midnight (00:00)
};
