import React, { useState } from 'react';
import { getTranslatedValue } from '@didomi/helpers';
import { DidomiChip, DidomiChipList, DidomiCollapse, DidomiIcon, DidomiIconButton } from '@didomi/ui-atoms-react';
import { CopyWithSnackbar, TextWithHighlighterAndTooltip, VendorListItemActionButtons } from '@components';
import { ExtendedVendorUi } from '@types';

interface VendorCardParams {
  vendor: ExtendedVendorUi;
  searchText: string;
  onVendorDelete: (arg0: Partial<ExtendedVendorUi>) => void;
}

export const VendorCard = (params: VendorCardParams) => {
  const {
    vendor: { name, id, sdk_id, default_purposes_name, legitimate_interest_purposes_name, links_readable, organization_id },
    searchText,
    onVendorDelete,
  } = params;
  const [isCollapsed, setCollapsed] = useState(true);
  const [isLegitimateCollapsed, setLegitimateCollapsed] = useState(true);

  return (
    <div data-cy={'vendor-card-' + name} className="w-full border-[1px] border-neutral-gray-3 border-solid rounded-lg shadow-language-card mb-4 text-primary-blue-6">
      <div className="flex flex-wrap justify-between items-center bg-neutral-gray-1 py-3 px-5">
        <h3 className="h3-bold">{getTranslatedValue(name)}</h3>

        <VendorListItemActionButtons vendor={{ id, organization_id }} onDeleteClicked={() => onVendorDelete({ id, name, sdk_id })} />
      </div>
      <div className="flex flex-wrap row-text-primary flex-col lg:flex-row lg:justify-between m-5">
        <div className="flex flex-1 lg:block w-full lg:w-1/3 ">
          <div className="lg:mb-4 flex-1">
            <h4 className="header-row-title-bold">VENDOR ID (API)</h4>
            <CopyWithSnackbar text="id">
              <TextWithHighlighterAndTooltip searchText={searchText} text={id || '-'} />
            </CopyWithSnackbar>
          </div>
          <div className="lg:mb-4 flex-1">
            <h4 className="header-row-title-bold">SDK ID</h4>
            {/* Casting toString() because some IDs or values received as 'text' may be numbers */}
            <CopyWithSnackbar text={sdk_id.toString()}>
              <TextWithHighlighterAndTooltip searchText={searchText} text={sdk_id.toString() || '-'} />
            </CopyWithSnackbar>
          </div>
          <div className="lg:mb-4 flex-1">
            <h4 className="header-row-title-bold">WEBSITES</h4>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              {links_readable
                .filter(link => link.value)
                .map(link => (
                  <a key={id + link.label} href={link.value} rel="noreferrer noopener" target="_blank" className="row-text-primary-underlined underline">
                    <span style={{ marginRight: '8px' }}>{link?.label}</span>
                    <DidomiIcon name="export" className="w-[10px]" />
                  </a>
                ))}
            </div>
          </div>
        </div>
        <div className="flex-1 border-solid border-t lg:border-t-0 lg:border-l border-neutral-gray-3 lg:pl-5 w-full lg:w-1/3">
          <h4 className="header-row-title-bold mb-4 mt-5 lg:mt-0">
            PURPOSES <span className="font-light">(CONSENT)</span>
          </h4>
          <DidomiCollapse
            isExpanded={!isCollapsed}
            minHeight={Math.min(120, default_purposes_name.length * 40)}
            className="max-w-xl lg:max-w-[240px] xl:max-w-[320px] 2xl:max-w-xl"
          >
            <DidomiChipList isVertical>
              {default_purposes_name.map((purpose, index) => (
                <DidomiChip key={`${id}-p-${index}`} label={purpose} rightIcon="" leftIcon="" className="mb-3" maxWidth="100%" class="max-w-full" />
              ))}
            </DidomiChipList>
          </DidomiCollapse>
          {default_purposes_name.length > 3 && (
            <div className="text-center">
              <DidomiIconButton type="button" variant="rounded" icon={isCollapsed ? 'down-on-sm' : 'up-on-sm'} onClick={() => setCollapsed(!isCollapsed)} size="large" />
            </div>
          )}
        </div>
        <div className="flex-1 border-solid border-t lg:border-t-0 lg:border-l border-neutral-gray-3 lg:pl-5 w-full lg:w-1/3">
          <h4 className="header-row-title-bold mb-4 mt-5 lg:mt-0">
            PURPOSES <span className="font-light">(LEGITIMATE INTEREST)</span>
          </h4>
          <DidomiCollapse
            isExpanded={!isLegitimateCollapsed}
            minHeight={Math.min(120, legitimate_interest_purposes_name.length * 40)}
            className="max-w-xl lg:max-w-[240px] xl:max-w-[320px] 2xl:max-w-xl"
          >
            <DidomiChipList isVertical>
              {legitimate_interest_purposes_name.map((purpose, index) => (
                <DidomiChip key={`${id}-pl-${index}`} label={purpose} rightIcon="" leftIcon="" className="mb-3" maxWidth="100%" class="max-w-full" />
              ))}
            </DidomiChipList>
          </DidomiCollapse>
          {legitimate_interest_purposes_name.length > 3 && (
            <div className="text-center">
              <DidomiIconButton
                type="button"
                variant="rounded"
                icon={isLegitimateCollapsed ? 'down-on-sm' : 'up-on-sm'}
                onClick={() => setLegitimateCollapsed(!isLegitimateCollapsed)}
                size="large"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
