import { apiRoutes } from '@constants';
import { useActiveOrganization, useReleaseFlag } from '@didomi/utility-react';
import { ReportStatus } from '@enums';
import { PaginatedResponse, Report, SortConfig } from '@types';
import { convertLegacyReportStatus } from '@utils';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut } from '../../useAxiosWithInterceptors.hook';

type UseReportsOptions = UseQueryOptions<PaginatedResponse<Report>, AxiosError> & {
  id?: string[];
  propertyId?: string;
  limit?: number;
  sort?: SortConfig<keyof Report>;
};

export const useReports = (options: UseReportsOptions = {}) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const [isReportsV2Enabled, isLoadingReportsV2Enabled] = useReleaseFlag('reports_v2');

  const fetch = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<Report>>(isReportsV2Enabled ? apiRoutes.reportsV2 : apiRoutes.reports, {
      params: {
        organization_id: organizationId,
        ...(options.id && { id: options.id }),
        ...(options.propertyId && { property_id: options.propertyId }),
        ...(options.limit && { $limit: options.limit }),
        ...(options.sort && { [`$sort[${options.sort.field}]`]: options.sort.dir === 'asc' ? 1 : -1 }),
      },
    });

    let allReports = response.data.data.map(report => ({
      ...report,
      provider: !isReportsV2Enabled ? 'legacy' : undefined,
    }));

    // Calculate 6 months ago date
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    const isDataEmpty = response.data.data.length === 0;
    const latestReport = response.data.data[response.data.data.length - 1];
    const isLatestReportNewerThanSixMonths = latestReport && new Date(latestReport.created_at) > sixMonthsAgo;

    // Only make second call if:
    // 1. Reports v2 is enabled AND
    // 2. Either there are no reports OR the latest report is less than 6 months old
    if (isReportsV2Enabled && (isDataEmpty || isLatestReportNewerThanSixMonths)) {
      const params = {
        organization_id: organizationId,
        ...(options.id && { id: options.id }),
        ...(options.propertyId && { property_id: options.propertyId }),
        ...(options.limit && { $limit: options.limit }),
        ...(options.sort && { [`$sort[modified]`]: options.sort.dir === 'asc' ? 1 : -1 }),
        ...(response.data.data.length > 0 && {
          created_at: { $lte: new Date(latestReport.created_at).toISOString() },
        }),
      };

      const olderReportsResponse = await axiosWithInterceptors.get<PaginatedResponse<Report>>(apiRoutes.reports, { params });

      allReports = [
        ...allReports,
        ...olderReportsResponse.data.data.map(report => ({
          ...report,
          provider: 'legacy',
        })),
      ];
    }

    return {
      ...response.data,
      data: allReports.map(report => ({
        ...report,
        status: convertLegacyReportStatus(report.status as ReportStatus),
      })),
    };
  };

  return useQuery<PaginatedResponse<Report>, AxiosError>(['reports', organizationId, ...(options.id || []), options.propertyId, isReportsV2Enabled], fetch, {
    ...options,
    enabled: !isLoadingReportsV2Enabled && isReportsV2Enabled !== null && options.enabled !== false,
  });
};
