import { useParams } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import { getCounterText } from '@didomi/helpers';
import { DidomiBottomBar, DidomiButton, DidomiHintbox, DidomiLoader, DidomiModal, DidomiModalContent, DidomiSkeleton, DidomiTooltip } from '@didomi/ui-atoms-react';
import { useSnackbar } from '@didomi/utility-react';
import { PropertyWithOptions } from '@enums';
import {
  useClonePropertyTrackerSettings,
  useCustomAndIABPurposes,
  usePatchPropertyTrackers,
  useProperties,
  usePropertyCookiePolicies,
  useTrackerExemptionCategories,
} from '@hooks';
import { AssignTrackerPurposesModal, CloneTrackerSettingsModal, EditTrackerModal, ExemptTrackerModal } from '@modals';
import { AggregatedTracker, PropertyTrackerItem } from '@types';
import { defineTrackersTableData } from '@utils';
import { ManageTrackerPolicyTable } from '../../components/OldManageTrackerPolicy/ManageTrackerPolicyTable';
import { useProperty } from '../../hooks/data/properties/useProperty.hook';
import { functionalScenarioAdapter } from '../../utils/functionalScenarioAdapter';

export const ManageTrackerPolicy = () => {
  const params = useParams();
  const { displaySnackbar } = useSnackbar();

  const propertyIdParam = params?.propertyId;

  const [selectedTrackerIds, setSelectedTrackerIds] = useState<string[]>([]);
  const [selectedPurposeIds, setSelectedPurposeIds] = useState<string[]>([]);
  const [selectedPropertyIds, setSelectedPropertyIds] = useState<string[]>([]);
  const [selectedTrackers, setSelectedTrackers] = useState<AggregatedTracker[]>([]);
  const [isSelectExemptedTracker, setIsSelectExemptedTracker] = useState(false);
  const [isSelectNonExemptedTracker, setIsSelectNonExemptedTracker] = useState(false);
  const [isSelectPurposeAssignedTrackers, setIsSelectPurposeAssignedTrackers] = useState(false);
  const [isOpenExemptTrackerDialog, setIsOpenExemptTrackerDialog] = useState(false);
  const [isOpenPurposeAssignmentDialog, setIsOpenPurposeAssignmentDialog] = useState(false);
  const [isOpenCloneTrackerSettingsDialog, setIsOpenCloneTrackerSettingsDialog] = useState(false);
  const [isOpenEditPropertyTrackerDialog, setIsOpenEditPropertyTrackerDialog] = useState(false);

  const { isLoading, data: property } = useProperty({ id: propertyIdParam, with: [PropertyWithOptions.LATEST_REPORTS] });
  const { isLoading: isLoadingPurposes, data: purposesToSelect } = useCustomAndIABPurposes();
  const { isLoading: isLoadingProperties, data: properties } = useProperties({
    sort: { field: 'name', dir: 'asc' },
    limit: 1000,
  });

  const { isLoading: isLoadingPropertyCookiePolicies, data: propertyCookiePolicies } = usePropertyCookiePolicies({
    propertyId: propertyIdParam,
  });

  const { isLoading: isLoadingExemptionCategories, data: exemptionCategories } = useTrackerExemptionCategories();
  const { isLoading: isSavingTrackerSettings, mutateAsync: patchPropertyTrackers } = usePatchPropertyTrackers();
  const { isLoading: isCloningTrackerSettings, mutateAsync: clonePropertyTrackersSetting } = useClonePropertyTrackerSettings();

  const trackers = useMemo(() => {
    const propertyTrackerItems: PropertyTrackerItem[] = propertyCookiePolicies?.data || [];

    return defineTrackersTableData(propertyTrackerItems, purposesToSelect);
  }, [propertyCookiePolicies, purposesToSelect]);

  const handleSubmitAssignPurposesAndExemption = async ({
    selectedPurposeIds,
    selectedExemptionCategoryId,
  }: {
    selectedPurposeIds?: string[];
    selectedExemptionCategoryId?: string;
  }) => {
    try {
      const selectedTrackers: any[] = trackers
        .filter(tracker => selectedTrackerIds.includes(tracker.id))
        .map(tracker => {
          const draftTracker = {
            name: tracker.name,
            is_regex: tracker?.is_regex || false,
            is_third_party: !tracker.first_party,
            initiator_vendor_id: tracker.initiator?.identifier,
            initiator_vendor_name: tracker.initiator?.label,
            vendor_id: tracker.vendor?.identifier,
            vendor_name: tracker.vendor?.label,
            host: tracker?.host,
            type: tracker.type,
            functional_scenario: functionalScenarioAdapter(tracker.functional_scenario),
            exemption_category_id: selectedExemptionCategoryId,
            purpose_ids: selectedPurposeIds,
          };

          // This isn't optimal but, we don't have a way with the current implementation
          // to identify which purpose ids (including TCF) have been unselected by the user
          // on the backend.
          // This is acceptable regarding the lifetime and the goal of this feature upgrade
          if (selectedPurposeIds?.length) {
            const diffPurposeIdsBetweenInitialPurposesAndSelectedPurposes: string[] = (tracker.purpose_ids || []).filter(
              (purposeId: string) => !(selectedPurposeIds || []).includes(purposeId),
            );
            (draftTracker as any).unselected_purpose_ids = diffPurposeIdsBetweenInitialPurposesAndSelectedPurposes;
          }

          return draftTracker;
        });

      await patchPropertyTrackers({
        propertyId: property.id,
        trackers: selectedTrackers,
      });

      setSelectedTrackerIds([]);
      setIsOpenExemptTrackerDialog(false);
      setIsOpenPurposeAssignmentDialog(false);

      await displaySnackbar('Tracker settings has been saved successfully', { icon: 'success-small' });
    } catch (error) {
      await displaySnackbar('There was an error on saving tracker settings: ' + error.message, { variant: 'error' });
    }
  };

  const handleSubmitClonePropertyTrackerSettings = async (selectedPropertyIds: string[]) => {
    try {
      await clonePropertyTrackersSetting({
        sourcePropertyId: property.id,
        targetPropertyIds: selectedPropertyIds,
      });

      setSelectedPropertyIds([]);
      setIsOpenCloneTrackerSettingsDialog(false);

      await displaySnackbar('Property tracker settings have been copied successfully!', { icon: 'success-small' });
    } catch (error) {
      await displaySnackbar('There was an error on  the property trackers: ' + error.message, { variant: 'error' });
    }
  };

  const handlerSubmitEditTracker = async (data: AggregatedTracker) => {
    try {
      const tracker = selectedTrackers[0];
      await patchPropertyTrackers({
        propertyId: property.id,
        trackers: [
          {
            name: data.name,
            is_regex: data.is_regex,
            is_third_party: !tracker.first_party,
            initiator_vendor_id: tracker?.initiator?.identifier,
            initiator_vendor_name: tracker?.initiator?.label,
            vendor_id: tracker?.vendor?.identifier,
            vendor_name: tracker?.vendor?.label,
            host: tracker?.host,
            type: tracker?.type,
            exemption_category_id: tracker?.exemption_category_id,
            purpose_ids: tracker?.purpose_ids,
            functional_scenario: functionalScenarioAdapter(tracker?.functional_scenario),
          } as Partial<PropertyTrackerItem>,
        ],
      });

      setSelectedTrackerIds([]);
      setIsOpenEditPropertyTrackerDialog(false);

      await displaySnackbar('Tracker settings has been saved successfully', { icon: 'success-small' });
    } catch (error) {
      await displaySnackbar('There was an error on saving tracker settings: ' + error.message, { variant: 'error' });
    }
  };

  const handlerTrackerSelection = (selectedTrackerIds: string[]) => {
    const selectedTrackers = trackers.filter(tracker => selectedTrackerIds.includes(tracker.id));
    const hasSelectExemptedTracker = selectedTrackers.some(tracker => tracker.exemption_category_id);
    const hasSelectNonExemptedTracker = selectedTrackers.some(tracker => !tracker.exemption_category_id);
    const hasSelectPurposeAssignedTrackers = selectedTrackers.some(tracker => tracker?.purpose_ids?.length > 0);

    const selectedPurposeIds = selectedTrackers
      .map(tracker => tracker.purpose_ids)
      .flat()
      .filter(Boolean);

    setSelectedTrackers(selectedTrackers);
    setSelectedTrackerIds(selectedTrackerIds);
    setSelectedPurposeIds(selectedPurposeIds);
    setIsSelectExemptedTracker(hasSelectExemptedTracker);
    setIsSelectNonExemptedTracker(hasSelectNonExemptedTracker);
    setIsSelectPurposeAssignedTrackers(hasSelectPurposeAssignedTrackers);
  };

  const isBottomBarOpened = selectedTrackerIds.length > 0;
  const isDisableModifyExemption = isSelectExemptedTracker && isSelectNonExemptedTracker;
  const propertiesToSelect = properties?.data.filter(property => property.id !== propertyIdParam);
  const isLoadingReady = isLoading || isLoadingProperties || isLoadingPurposes || isLoadingExemptionCategories || isLoadingPropertyCookiePolicies;

  return (
    <div className="pb-unsafe-bottom-space">
      <DidomiHintbox title-text="Choose one or more trackers that you want to configure.">
        <div>The compliance monitoring checks for all active trackers. On this page you can configure exemptions and purposes for each of them.</div>
      </DidomiHintbox>
      <div className="mt-[15px]">
        <DidomiSkeleton isLoading={isLoadingReady} variant="unit" className="h-40 w-full">
          {!isLoadingReady && (
            <div>
              <ManageTrackerPolicyTable
                trackers={trackers}
                selectedIds={selectedTrackerIds}
                onSelectionChange={handlerTrackerSelection}
                setOpenCloneTrackerSettingsDialog={setIsOpenCloneTrackerSettingsDialog}
              />
            </div>
          )}
        </DidomiSkeleton>
      </div>

      <DidomiBottomBar
        class="-mx-l !fixed w-full"
        icon="check"
        isOpen={isBottomBarOpened}
        onClose={() => setSelectedTrackerIds([])}
        text={isBottomBarOpened ? `${getCounterText(selectedTrackerIds.length, 'tracker')} selected` : null}
      >
        <div slot="actions" style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          <DidomiButton size="medium" onClick={() => setIsOpenPurposeAssignmentDialog(true)}>
            {(isSelectPurposeAssignedTrackers && 'Modify purpose') || 'Assign purpose'}&nbsp;
          </DidomiButton>
          {isDisableModifyExemption ? (
            <>
              <DidomiTooltip contentElementId={`disable_exemption_button_help`} variant="helper" distance="xxxs">
                <span>
                  <DidomiButton size="medium" onClick={() => setIsOpenExemptTrackerDialog(true)} disabled={true}>
                    {(!isSelectExemptedTracker && 'Exempt tracker') || (!isSelectNonExemptedTracker && 'Modify exemption') || 'Modify exemption'}&nbsp;
                  </DidomiButton>
                </span>
              </DidomiTooltip>
              <div style={{ display: 'none' }} id={`disable_exemption_button_help`}>
                <p>You have selected multiple trackers that have different exemption status.</p>
                <p>&nbsp;</p>
                <p>In order to change exemption status, please select trackers that have the same settings.</p>
              </div>
            </>
          ) : (
            <>
              <DidomiButton size="medium" onClick={() => setIsOpenExemptTrackerDialog(true)}>
                {(!isSelectExemptedTracker && 'Exempt tracker') || (!isSelectNonExemptedTracker && 'Modify exemption') || 'Modify exemption'}&nbsp;
              </DidomiButton>
            </>
          )}
          {selectedTrackers.length === 1 && !selectedTrackers[0].is_regex && (
            <DidomiButton data-testid="edit-tracker-button" size="medium" onClick={() => setIsOpenEditPropertyTrackerDialog(true)}>
              Edit
            </DidomiButton>
          )}
        </div>
      </DidomiBottomBar>

      {/** Dialog to display busy state on data processing */}
      <DidomiModal isOpen={isSavingTrackerSettings || isCloningTrackerSettings}>
        <DidomiLoader></DidomiLoader>
        <DidomiModalContent className="mt-m">{'We are saving your tracker settings ...'}</DidomiModalContent>
      </DidomiModal>

      {/** Dialog to display modify purposes form */}
      {purposesToSelect && (
        <AssignTrackerPurposesModal
          purposes={purposesToSelect}
          selectedPurposeIds={selectedPurposeIds}
          isOpen={isOpenPurposeAssignmentDialog}
          onClose={setIsOpenPurposeAssignmentDialog}
          setSelectedPurposeIds={setSelectedPurposeIds}
          onSubmit={handleSubmitAssignPurposesAndExemption}
        />
      )}

      {/** Dialog to display modify exemption form */}
      <ExemptTrackerModal
        tracker={selectedTrackers[0] || null}
        isOpen={isOpenExemptTrackerDialog}
        onClose={setIsOpenExemptTrackerDialog}
        hideRemoveExemption={isSelectNonExemptedTracker}
        exemptionCategories={exemptionCategories?.data}
        onSubmit={handleSubmitAssignPurposesAndExemption}
      />

      {/** Dialog to display clone property tracker settings form */}
      {propertiesToSelect && (
        <CloneTrackerSettingsModal
          properties={propertiesToSelect}
          selectedPropertyIds={selectedPropertyIds}
          isOpen={isOpenCloneTrackerSettingsDialog}
          onOptionSelect={setSelectedPropertyIds}
          onClose={setIsOpenCloneTrackerSettingsDialog}
          onSubmit={handleSubmitClonePropertyTrackerSettings}
        />
      )}

      {/** Dialog to create new tracker by modify an existing tracker information by place regex as name of the tracker */}
      <EditTrackerModal tracker={selectedTrackers[0]} isOpen={isOpenEditPropertyTrackerDialog} onClose={setIsOpenEditPropertyTrackerDialog} onSubmit={handlerSubmitEditTracker} />
    </div>
  );
};
