import React from 'react';
import { NoticePosition, Regulation } from '@didomi/cmp-generator';
import { produce } from '@didomi/helpers';
import { DidomiIcon, DidomiThemingCardOption } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { tx } from '@twind/core';
import { AppTextCustomization, ExpandableSection, ExpandableSectionToggle, NoticeClosingSwitch } from '@components';
import { useCustomizationRegulationConfig, useEditLocalConsentNoticeConfig, useLocalConsentNoticeConfig, useLocalRegulationConfig, useCustomizationStepOptions } from '@hooks';
import { ConsentNoticeConfigRegulation, NOTICE_FORMATS } from '@types';
import { ACCESS_POLICIES_CONFIG, REGULATION_DICTIONARY, produceListOfChanges, isFormatSelectedForPlatform } from '@utils';
import { CustomizationLocalExceptions } from '../CustomizationLocalExceptions/CustomizationLocalExceptions';
import CustomizationRegulationOptionsCTVFocus from '../CustomizationRegulationOptionsCTVFocus/CustomizationRegulationOptionsCTVFocus';

type TextStyleField = 'titleTextSize' | 'titleAlignment' | 'titleTextColor' | 'descriptionTextSize' | 'descriptionAlignment' | 'descriptionTextColor';

interface CustomizationSpecificOptionsProps {
  noticeId: string;
  regulationId: string;
}

export const CustomizationRegulationOptions = ({ noticeId, regulationId }: CustomizationSpecificOptionsProps) => {
  const { data: localConsentNoticeConfig } = useLocalConsentNoticeConfig(noticeId);
  const { activeRegulationIds } = useLocalRegulationConfig(noticeId);
  const { mutateAsync: updateLocalConfig } = useEditLocalConsentNoticeConfig(localConsentNoticeConfig?.id);
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);

  const { hasNoticeFormat, hasContinueWithoutAgreeing, hasDisplayX, hasNegativeActionButton, hasTextStyles, hasStickyButtons, localExceptions } = useCustomizationRegulationConfig(
    regulationId,
    localConsentNoticeConfig,
  );
  const { regulationConfig, regulationConfigIndex, aggregatedRegConfig } = useLocalRegulationConfig(noticeId, regulationId);
  const { previewRegulation, setPreviewRegulation } = useCustomizationStepOptions({
    activeRegulationIds,
  });

  const updateRegulations = (updatedRegulation: ConsentNoticeConfigRegulation) => {
    updateLocalConfig(produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}]`, updatedRegulation));
  };

  const handleBannerTypeSelection = (newBannerLayoutEvent: CustomEvent<string>) => {
    const newBannerLayout = newBannerLayoutEvent.detail;

    const isMobileAppPlatform = localConsentNoticeConfig?.platform === 'app';

    const updatesToApply = [];

    if (isMobileAppPlatform) {
      updatesToApply.push({ path: 'config.theme.fullscreen', value: newBannerLayout === 'fullscreen' });

      if (newBannerLayout !== 'fullscreen' && isStickyButtonsSet) {
        // If changing the layout to anything other than fullscreen, and stickybuttons is enabled, disable it.
        updatesToApply.push({ path: `config.theme.notice.stickyButtons`, value: false });
      }
    }

    // Update position and content
    const configNoticeContent = aggregatedRegConfig.config?.notice?.content || {};

    const { popup, notice, ...existingConfigNoticeContent } = configNoticeContent;

    const isPopup = newBannerLayout === 'popup';

    const contentText = notice || popup;
    const contentField = isPopup && !isMobileAppPlatform ? 'popup' : 'notice';

    const newContentValue = { ...existingConfigNoticeContent, [contentField]: contentText };

    updatesToApply.push({ path: 'config.notice.position', value: newBannerLayout as NoticePosition });
    updatesToApply.push({ path: `config.notice.content`, value: newContentValue });

    updateRegulations(produceListOfChanges(updatesToApply, regulationConfig));
  };

  const handleStickyButtonsChange = (newValueEvent: CustomEvent<string>) => {
    const withStickyButtons = newValueEvent.detail === 'selected';

    const updates = withStickyButtons
      ? [
          { path: 'config.theme.notice.stickyButtons', value: true },
          { path: 'negative_action_link', value: true },
          { path: 'config.theme.fullscreen', value: true },
        ]
      : [{ path: 'config.theme.notice.stickyButtons', value: false }];

    const updatedRegulation = produceListOfChanges(updates, regulationConfig);
    updateRegulations(updatedRegulation);
  };

  const handleDisagreeAndCloseChange = (newValueEvent: CustomEvent<string>) => {
    const isNegativeActionLinkSelected = newValueEvent.detail === 'selected';
    const updatedRegulation = {
      ...regulationConfig,
      negative_action_button: isNegativeActionLinkSelected,
      negative_action_link: false,
    };
    updateRegulations(updatedRegulation);
  };

  const handleContinueWithoutAgreeingLinkChange = (newValueEvent: CustomEvent<string>) => {
    const isNegativeActionLinkSelected = newValueEvent.detail === 'selected';
    const updatedRegulation = {
      ...regulationConfig,
      negative_action_link: isNegativeActionLinkSelected,
      negative_action_link_format: 'text',
      negative_action_button: false,
    } as const;
    updateRegulations(updatedRegulation);
  };

  const handleDisplayXClosingIcon = (newValueEvent: CustomEvent<string>) => {
    const isNegativeActionLinkSelected = newValueEvent.detail === 'selected';
    // TODO: should we set negative_action_button: false ??
    const updatedRegulation = { ...regulationConfig, negative_action_link: isNegativeActionLinkSelected, negative_action_link_format: 'cross' } as const;
    updateRegulations(updatedRegulation);
  };

  const handleTextStylesChange = (field: TextStyleField, value: string | number) => {
    const updatedRegulation = produce(regulationConfig, `config.theme.notice.${field}`, value) as ConsentNoticeConfigRegulation;
    updateRegulations(updatedRegulation);
  };

  const handleCtvFocusOnAgreeAndCloseChange = (newValueEvent: CustomEvent<string>) => {
    const isCtvFocusOnAgreeAndCloseSelected = newValueEvent.detail === 'ctv-focus-on-agree-and-close';
    updateLocalConfig(produce(localConsentNoticeConfig, 'ctv_focus_on_agree_and_close', isCtvFocusOnAgreeAndCloseSelected));
  };

  const hasExtraFields = hasContinueWithoutAgreeing || hasDisplayX || hasNegativeActionButton || hasStickyButtons;
  const isMobileAppPlatform = localConsentNoticeConfig?.platform === 'app';
  const isCtvPlatform = localConsentNoticeConfig?.platform === 'ctv';
  const isStickyButtonsSet = Boolean(aggregatedRegConfig?.config?.theme?.notice?.stickyButtons);

  if (!regulationConfig) {
    return null;
  }

  return (
    <ExpandableSection
      id={regulationConfig.id}
      variant="top-level"
      key={regulationConfig.id}
      header={
        <div className="flex items-center gap-xs">
          <DidomiIcon name={REGULATION_DICTIONARY[regulationConfig.regulation_id].iconMd} />
          <div className="font-semibold">{REGULATION_DICTIONARY[regulationConfig.regulation_id]?.titleText}</div>
          <ExpandableSectionToggle className="ml-auto" withBorder={false} a11yLabel={regulationId} />
        </div>
      }
      expanded={previewRegulation === regulationId}
      onToggleExpanded={expanded => {
        if (expanded) {
          setPreviewRegulation(regulationId as Regulation);
        }
      }}
    >
      <div className="mt-xs">
        {isMobileAppPlatform && hasTextStyles && (
          <>
            <AppTextCustomization
              label="Title"
              size={aggregatedRegConfig.config?.theme?.notice?.titleTextSize}
              color={aggregatedRegConfig.config?.theme?.notice?.titleTextColor}
              alignment={aggregatedRegConfig.config?.theme?.notice?.titleAlignment}
              disabled={!isCMPEditor}
              onSizeChange={value => handleTextStylesChange('titleTextSize', value)}
              onColorChange={value => handleTextStylesChange('titleTextColor', value)}
              onAlignmentChange={value => handleTextStylesChange('titleAlignment', value)}
            />
            <hr className="block h-0 my-xs border-t-1 border-neutral-gray-3" />

            <AppTextCustomization
              label="Description text"
              size={aggregatedRegConfig.config?.theme?.notice?.descriptionTextSize}
              color={aggregatedRegConfig.config?.theme?.notice?.descriptionTextColor}
              alignment={aggregatedRegConfig.config?.theme?.notice?.descriptionAlignment}
              disabled={!isCMPEditor}
              onSizeChange={value => handleTextStylesChange('descriptionTextSize', value)}
              onColorChange={value => handleTextStylesChange('descriptionTextColor', value)}
              onAlignmentChange={value => handleTextStylesChange('descriptionAlignment', value)}
            />
            <hr className="block h-0 my-xs border-t-1 border-neutral-gray-3" />
          </>
        )}

        {hasNoticeFormat && (
          <div className={tx({ 'mb-s': hasExtraFields })}>
            <div className="text-body-small text-primary-pink-5 mb-xxs font-semibold">Choose a notice format</div>
            <div className={`grid grid-cols-${NOTICE_FORMATS[localConsentNoticeConfig?.platform]?.length} grid-rows-1 grid-flow-col gap-xxxs`}>
              {localConsentNoticeConfig?.platform &&
                NOTICE_FORMATS[localConsentNoticeConfig?.platform]?.map(format => (
                  <DidomiThemingCardOption
                    key={format.value}
                    data-testid={format.dataTestid}
                    iconDefault={format.iconDefault}
                    iconSelected={format.iconSelected}
                    disabled={format.disabled || !isCMPEditor}
                    className="w-full h-full children:(w-full h-full flex)"
                    value={format.value}
                    label={format.label}
                    selected={isFormatSelectedForPlatform(aggregatedRegConfig, format.value, localConsentNoticeConfig?.platform)}
                    onSelectOption={handleBannerTypeSelection}
                  />
                ))}
            </div>
          </div>
        )}

        <div className="flex flex-col gap-xs">
          {isMobileAppPlatform && hasStickyButtons && (
            <NoticeClosingSwitch data-testid="sticky-buttons" image="sticky-buttons" value={isStickyButtonsSet} disabled={!isCMPEditor} onValueChange={handleStickyButtonsChange}>
              Stick the action buttons while scrolling
            </NoticeClosingSwitch>
          )}

          {hasNegativeActionButton && (
            <NoticeClosingSwitch
              data-testid="disagree-and-close"
              image="disagree-and-close"
              value={aggregatedRegConfig?.negative_action_button}
              disabled={!isCMPEditor}
              onValueChange={handleDisagreeAndCloseChange}
            >
              <div>Display the button:</div>
              <strong className="font-semibold">Disagree & close</strong>
            </NoticeClosingSwitch>
          )}

          {hasContinueWithoutAgreeing && (
            <NoticeClosingSwitch
              data-testid="continue-without-agreeing"
              image="continue-without-agreeing"
              value={aggregatedRegConfig?.negative_action_link && aggregatedRegConfig?.negative_action_link_format === 'text'}
              disabled={!isCMPEditor}
              onValueChange={handleContinueWithoutAgreeingLinkChange}
            >
              <div>Display the link:</div>
              <strong className="font-semibold">Continue without agreeing</strong>
            </NoticeClosingSwitch>
          )}

          {hasDisplayX && !isCtvPlatform && (
            <NoticeClosingSwitch
              data-testid="display-x"
              image="display-x"
              value={aggregatedRegConfig?.negative_action_link && aggregatedRegConfig?.negative_action_link_format === 'cross'}
              disabled={!isCMPEditor}
              onValueChange={handleDisplayXClosingIcon}
            >
              <div>
                Display the “<strong className="font-semibold">X</strong>”
              </div>
              <div>closing icon</div>
            </NoticeClosingSwitch>
          )}
        </div>

        {!!localExceptions.length && <CustomizationLocalExceptions noticeId={noticeId} regulationId={regulationId} regulationLocalExceptions={localExceptions} />}

        {isCtvPlatform && (
          <CustomizationRegulationOptionsCTVFocus
            aggregatedRegConfig={aggregatedRegConfig}
            disabled={!isCMPEditor}
            handleCtvFocusOnAgreeAndCloseChange={handleCtvFocusOnAgreeAndCloseChange}
          />
        )}
      </div>
    </ExpandableSection>
  );
};
