import React, { useMemo, useState } from 'react';
import { DidomiChip, DidomiCollapse, DidomiIcon, DidomiModal, DidomiModalContent, DidomiModalHeader } from '@didomi/ui-atoms-react';
import { parse } from 'psl';
import './modal-css-overwrite.css';
import { TRANSLATED_SCENARIOS_MAP_OLD } from '@constants';
import { Report, Request, VendorGraphNode, VendorGraphNodeTracker } from '@types';
import { ellipsis, getTrackerAgeInMonthOrDays } from '@utils';

interface ModalProps {
  report: Report;
  isOpen: boolean;
  vendorId: string;
  onClose: () => void;
  mergedVendorsIds?: string[];
  displayOnlyName?: boolean;
  onVendorChange: (id: string) => void;
  nodes?: any;
}
/**
 * Merges multiple vendor data objects into a single object based on the specified vendor IDs from params.
 * When we have list of vendorIds as param then we pull all the vendor details related to that IDs and
 * merge some of the attributes from them into a single object (first item) to diplay in the popin as integrated one.
 * Because we decide to display only the domains for the vendor and all the details related to subdomains should integrated / merged under domains.
 *
 * @param {Report} report - The report object containing vendor nodes.
 * @param {string[]} vendorIds - An array of vendor IDs to be merged.
 * @returns {object|null} Returns a merged vendor object with combined tracking and initiation data,
 *                         or null if no vendors are found.
 */
const returnMergedVendorDetail = (nodes: any = {}, vendorIds: string[]) => {
  const vendors = vendorIds.map(vendorId => nodes?.[vendorId]).filter(Boolean);
  if (vendors.length === 0) {
    return null;
  }

  const initialVendor = vendors[0];
  const { domain } = parse(initialVendor.label);

  const mergedData = vendors.reduce(
    (acc, vendor) => {
      vendor.initiated_trackers.forEach(item => acc.initiated_trackers.add(item));
      vendor.initiated_from.forEach(item => acc.initiated_from.add(item));
      vendor.initiated_to.forEach(item => acc.initiated_to.add(item));
      return acc;
    },
    {
      initiated_trackers: new Set<VendorGraphNodeTracker>(),
      initiated_from: new Set<string>(),
      initiated_to: new Set<string>(),
    },
  );

  return {
    ...initialVendor,
    label: domain,
    initiated_trackers: Array.from(mergedData.initiated_trackers),
    initiated_from: Array.from(mergedData.initiated_from),
    initiated_to: Array.from(mergedData.initiated_to),
  };
};

export const InitiatorDetailModal = ({ isOpen, vendorId, onVendorChange, onClose, report, mergedVendorsIds = [], displayOnlyName = false, nodes = {} }: ModalProps) => {
  const [expandVendorDetails, setExpandVendorDetails] = useState(true);
  const [expandPrivacyPolicy, setExpandPrivacyPolicy] = useState(false);
  const [expandVendorOwnedDomain, setExpandVendorOwnedDomain] = useState(false);
  const [expandDroppedTrackers, setExpandDroppedTrackers] = useState(false);
  const [expandRequestedByVendors, setExpandRequestedByVendors] = useState(false);
  const [expandInitiatedToVendors, setExpandInitiatedToVendors] = useState(false);

  const selectedVendor = useMemo(() => {
    return returnMergedVendorDetail(nodes, mergedVendorsIds) || nodes?.[vendorId];
  }, [nodes, vendorId, mergedVendorsIds]) as VendorGraphNode;

  const domainsFoundDuringScan = useMemo(() => {
    return Array.from(new Set((report?.details_json?.requests || []).map((item: Request) => item.url_sld).filter(Boolean)));
  }, [report]);

  const handleVendorChange = (id: string) => {
    // Ensure state reset before setting new ID when the modal is opened
    setExpandPrivacyPolicy(false);
    setExpandVendorOwnedDomain(false);
    setExpandDroppedTrackers(false);
    setExpandRequestedByVendors(false);
    setExpandInitiatedToVendors(false);
    onVendorChange(id);
  };

  return (
    <div>
      <DidomiModal
        isOpen={isOpen}
        variant="main"
        closable={true}
        onOpenChange={evt => {
          if (evt.type === 'openChange' && !evt.detail) {
            onClose();
          }
        }}
      >
        <DidomiModalHeader modal-title={selectedVendor?.label || selectedVendor?.id}></DidomiModalHeader>
        <DidomiModalContent className="w-full">
          {/** Vendor details */}
          {!displayOnlyName && (
            <>
              <button
                id="panel-1-toggle"
                onClick={() => setExpandVendorDetails(!expandVendorDetails)}
                className="border border-2 flex flex-row items-center agnostik-modal text-primary-blue-6 font-semibold item-box"
              >
                <div className="panel-text">Vendor details</div>
                <div className="down-arrow">
                  <DidomiIcon name="down-on" className="ml-auto" />
                </div>
              </button>
              <DidomiCollapse id="panel-1" is-expanded={expandVendorDetails} className="border border-2 mb-xs no-top-border">
                <ul className="text-left text-body-small text-primary-blue-6" style={{ padding: '16px' }}>
                  <li className="mb-m flex items-center">
                    <span>Vendor in TCF :</span>
                    {selectedVendor?.namespaces && selectedVendor?.namespaces['iab2'] ? (
                      <div className="flex items-center">
                        <DidomiIcon name="success-medium" className="ml-xs" />
                        <span className="ml-xs">
                          <DidomiChip label={`ID #${selectedVendor?.namespaces['iab2']}`} variation="basic" />
                        </span>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <DidomiIcon name="failure-medium" className="ml-xs" />
                        <span className="ml-xs">
                          <DidomiChip label="Unknown" variation="basic-outline" basic-type="empty"></DidomiChip>
                        </span>
                      </div>
                    )}
                  </li>
                </ul>
              </DidomiCollapse>
            </>
          )}

          {/** Privacy policy links */}
          {selectedVendor?.vendor_links?.privacypolicy && (
            <>
              <button
                id="panel-2-toggle"
                onClick={() => setExpandPrivacyPolicy(!expandPrivacyPolicy)}
                className="border border-2 flex flex-row items-center agnostik-modal text-primary-blue-6 font-semibold item-box"
              >
                <div className="panel-text">Privacy Policy</div>
                <div className="down-arrow">
                  <DidomiIcon name="down-on" className="ml-auto" />
                </div>
              </button>
              <DidomiCollapse id="panel-2" is-expanded={expandPrivacyPolicy} className="border border-2 mb-xs no-top-border">
                <div className="w-full text-center text-primary-blue-6 underline privacy-policy-link-box">
                  <a href={selectedVendor.vendor_links.privacypolicy} target="_blank" rel="noopener noreferrer">
                    {selectedVendor.vendor_links.privacypolicy}
                  </a>
                </div>
              </DidomiCollapse>
            </>
          )}

          {/** Domains owned by vendor */}
          {selectedVendor?.owned_domains?.length > 0 && (
            <>
              <button
                id="panel-3-toggle"
                onClick={() => (selectedVendor?.owned_domains.length ? setExpandVendorOwnedDomain(!expandVendorOwnedDomain) : undefined)}
                className="border border-2 flex flex-row items-center agnostik-modal text-primary-blue-6 font-semibold item-box"
                style={{ cursor: selectedVendor?.owned_domains.length ? 'pointer' : 'inherit' }}
              >
                <div className="panel-text">{`Domains owned by the vendor: ${selectedVendor?.owned_domains.length}`}</div>
                {!!selectedVendor?.owned_domains.length && (
                  <div className="down-arrow">
                    <DidomiIcon name="down-on" className="ml-auto" />
                  </div>
                )}
              </button>
              <DidomiCollapse id="panel-3" is-expanded={expandVendorOwnedDomain} className="border border-2 mb-xs no-top-border">
                <div className="vendor-owned-domains-box">
                  {selectedVendor.owned_domains.map(domain => {
                    return (
                      <span key={domain} className="domain-chip-wrapper">
                        <DidomiChip label={domain} basic-type="neutral" variation={domainsFoundDuringScan.includes(domain) ? 'basic' : 'basic-outline'} />
                      </span>
                    );
                  })}
                </div>
              </DidomiCollapse>
            </>
          )}

          {/** Dropped trackers */}
          <button
            id="panel-4-toggle"
            onClick={() => (selectedVendor?.initiated_trackers.length ? setExpandDroppedTrackers(!expandDroppedTrackers) : null)}
            className="border border-2 flex flex-row items-center agnostik-modal text-primary-blue-6 font-semibold item-box"
            style={{ cursor: selectedVendor?.initiated_trackers.length ? 'pointer' : 'inherit' }}
          >
            <div className="panel-text">{`Dropped trackers: ${selectedVendor?.initiated_trackers.length}`}</div>
            {!!selectedVendor?.initiated_trackers.length && (
              <div className="down-arrow">
                <DidomiIcon name="down-on" className="ml-auto" />
              </div>
            )}
          </button>
          <DidomiCollapse id="panel-4" is-expanded={expandDroppedTrackers} className="border border-2 mb-xs no-top-border">
            <div className="text-body-small text-primary-blue-6">
              <div className="flex w-full justify-between justify-items-stretch content-center text-left font-bold" style={{ padding: '12px', borderBottom: '1px solid' }}>
                <div className="w-full">Name</div>
                <div className="w-full">Type</div>
                <div className="w-full">Lifetime</div>
                <div className="w-full">User behaviour</div>
              </div>
              {selectedVendor?.initiated_trackers.map((tracker: VendorGraphNodeTracker, idx: number) => {
                return (
                  <div key={idx} className="flex w-full justify-between text-left" style={{ padding: '12px', borderBottom: '1px solid' }}>
                    <div className="w-full break-all" style={{ wordBreak: 'break-all' }}>
                      {ellipsis(tracker.name, 15)}
                    </div>
                    <div className="w-full">{tracker.type}</div>
                    <div className="w-full">{getTrackerAgeInMonthOrDays(tracker.lifetime)}</div>
                    <div className="w-full">{TRANSLATED_SCENARIOS_MAP_OLD[tracker.functional_scenario]}</div>
                  </div>
                );
              })}
            </div>
          </DidomiCollapse>

          {/** Requested by */}
          <button
            id="panel-5-toggle"
            onClick={() => (selectedVendor?.initiated_from.length ? setExpandRequestedByVendors(!expandRequestedByVendors) : null)}
            className="border border-2 flex flex-row items-center agnostik-modal text-primary-blue-6 font-semibold item-box"
            style={{ cursor: selectedVendor?.initiated_from.length ? 'pointer' : 'inherit' }}
          >
            <div className="panel-text">{`Requested by ${selectedVendor?.initiated_from.length} vendors`}</div>
            {!!selectedVendor?.initiated_from.length && (
              <div className="down-arrow">
                <DidomiIcon name="down-on" className="ml-auto" />
              </div>
            )}
          </button>
          <DidomiCollapse id="panel-5" is-expanded={expandRequestedByVendors} className="border border-2 mb-xs no-top-border">
            {selectedVendor?.initiated_from.map((key, idx) => {
              return selectedVendor?.id !== key ? (
                <button
                  key={idx}
                  className="w-full text-center text-primary-blue-6"
                  style={{
                    padding: '16px',
                    borderBottom: '1px solid',
                    cursor: !key ? 'inherit' : key === selectedVendor?.id ? 'inherit' : 'pointer',
                  }}
                  onClick={() => {
                    handleVendorChange(key);
                  }}
                >
                  {nodes[key].label}
                </button>
              ) : (
                <div
                  key={idx}
                  className="w-full text-center text-primary-blue-6"
                  style={{
                    padding: '16px',
                    borderBottom: '1px solid',
                    cursor: !key ? 'inherit' : key === selectedVendor?.id ? 'inherit' : 'pointer',
                  }}
                >
                  {nodes[key].label}
                </div>
              );
            })}
          </DidomiCollapse>

          {/** Initiated request to */}
          <button
            id="panel-6-toggle"
            onClick={() => (selectedVendor?.initiated_to.length ? setExpandInitiatedToVendors(!expandInitiatedToVendors) : null)}
            className="border border-2 flex flex-row items-center agnostik-modal text-primary-blue-6 font-semibold item-box"
            style={{ cursor: selectedVendor?.initiated_to.length ? 'pointer' : 'inherit' }}
          >
            <div className="panel-text">{`Initiated request to ${selectedVendor?.initiated_to.length} vendors`}</div>
            {!!selectedVendor?.initiated_to.length && (
              <div className="down-arrow">
                <DidomiIcon name="down-on" className="ml-auto" />
              </div>
            )}
          </button>
          <DidomiCollapse id="panel-6" is-expanded={expandInitiatedToVendors} className="border border-2 no-top-border">
            {selectedVendor?.initiated_to.map((key, idx) => {
              return selectedVendor?.id !== key ? (
                <button
                  key={idx}
                  className="w-full text-center text-primary-blue-6 underline"
                  style={{ padding: '16px', borderBottom: '1px solid', cursor: key === selectedVendor?.id ? 'inherit' : 'pointer' }}
                  onClick={() => {
                    handleVendorChange(key);
                  }}
                >
                  {nodes[key].label}
                </button>
              ) : (
                <div
                  key={idx}
                  className="w-full text-center text-primary-blue-6 underline"
                  style={{ padding: '16px', borderBottom: '1px solid', cursor: key === selectedVendor?.id ? 'inherit' : 'pointer' }}
                >
                  {nodes[key].label}
                </div>
              );
            })}
          </DidomiCollapse>
        </DidomiModalContent>
      </DidomiModal>
    </div>
  );
};
