import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { VendorNamespace } from '@enums';
import { useAxiosWithLogOut, usePaginationQueryParams } from '@hooks';
import { AggregatedVendor } from '@interfaces';
import { PaginatedResponse, SortConfig } from '@types';

const DEFAULT_LIMIT = 1000;
const SEARCH_DEBOUNCE_TIME = 500;

type UseAggregatedVendorsOptions = UseQueryOptions<PaginatedResponse<AggregatedVendor>, AxiosError> & {
  ids?: string[];
  unmatchedDomainsOnly?: boolean;
  functional_namespaces?: VendorNamespace[];
  limit?: number;
  skip?: number;
  sort?: SortConfig<keyof AggregatedVendor>;
};

export const useAggregatedVendors = ({ ids, unmatchedDomainsOnly = false, functional_namespaces, ...options }: UseAggregatedVendorsOptions) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const { limit, page, skip, search, setLimit, setPage, setSearch } = usePaginationQueryParams({
    debounceSearch: true,
    debounceTime: SEARCH_DEBOUNCE_TIME,
  });

  const fetchVendors = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<AggregatedVendor>>(`${CONFIG.environmentConfig.apiBaseUrl}reports/compliances/vendors`, {
      params: {
        organization_id: organizationId,
        report_id: ids || [],
        $limit: options.limit || limit || DEFAULT_LIMIT,
        $skip: skip || 0,
        ...(search && { id: { $ilike: search } }),
        ...(unmatchedDomainsOnly && { partner_id: 'null' }),
        ...(functional_namespaces && { functional_namespace: functional_namespaces }),
        ...(options.sort && {
          [`$sort[${options.sort.field}]`]: options.sort.dir === 'asc' ? 1 : -1,
        }),
      },
    });

    return response.data;
  };

  return {
    ...useQuery<PaginatedResponse<AggregatedVendor>, AxiosError>(
      ['aggregated-vendors', organizationId, ids || [], limit, skip, unmatchedDomainsOnly, options.sort, search],
      fetchVendors,
      {
        ...options,
      },
    ),
    paginator: {
      page,
      limit,
      search,
      setPage,
      setLimit,
      setSearch,
    },
  };
};
